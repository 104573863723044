import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useEffect} from 'react'
import {useState} from 'react'
import {Link} from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  MixedWidget8,
  TablesWidget10,
  TilesWidget1,
  TilesWidget2,
  TilesWidget3,
  TilesWidget4,
  TilesWidget5,
} from '../../../_metronic/partials/widgets'

import ProjectButton from './ProjectButton'
import MyChart from './MyChart'
import firebase from '../../firebase/FirebaseConfig'
import {useUser} from '../../modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import PeriodButton from './PeriodButton'
import {CreateWidget} from '../create-widget/CreateWidget'
import {
  ThemeModeType,
  themeModelSKey,
  themeMenuModeLSKey,
  useThemeMode,
} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {EditProject} from './EditProject'
import MyChartEditWidget from './MyChartEditWidget'

import {useScreenshot, createFileName} from 'use-react-screenshot'
import {createRef} from 'react'

export function DashboardWrapper() {
  const [dashboardName, setDashboardName] = useState('')
  const [dashboardId, setDashboardId] = useState('')
  const [dashboardList, setDashboardList] = useState([])

  const [periodType, setPeriodType] = useState('')

  const [customStartDate, setCustomStartDate] = useState(new Date(new Date().getTime() - 86400000))
  const [customStopDate, setCustomStopDate] = useState(new Date(new Date().getTime() - 86400000))
  const [triggerEffectCustom, setTriggerEffectCustom] = useState(false)
  const [refreshDashboard, setRefreshDashboard] = useState(0)

  // const [widgetBorderColor, setWidgetBorderColor] = useState('')

  const {user} = useUser()
  const db = firebase.firestore()
  // const themeModeValue = localStorage.getItem(themeModelSKey);
  // const themeMenuMode = localStorage.getItem(themeMenuModeLSKey);
  // console.log(themeModeValue)
  // console.log(themeMenuMode)

  const ref = createRef(null)
  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  })
  // const getImage = () => takeScreenshot(ref.current)
  const download = (image, {name = dashboardName + '_' + periodType, extension = 'jpg'} = {}) => {
    const a = document.createElement('a')
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download)

  useEffect(() => {
    const saved = localStorage.getItem('dashboardId')
    console.log(saved)
    if (saved == '') {
    } else {
      const initialValue = JSON.parse(saved)
      setDashboardId(initialValue)
      const saved_periodType = localStorage.getItem('periodType')
      const initialValuePeriodType = JSON.parse(saved_periodType)
      setPeriodType(initialValuePeriodType)
      if (initialValue !== null) {
        db.collection('project')
          .doc(initialValue)
          .get()
          .then((doc) => {
            if (doc.data() && doc.data().name) {
              setDashboardName(doc.data().name)
            } else {
              setDashboardName('Select Dashboard')

              // Handle the case when 'name' is not present in doc.data()
              // You might want to set a default value or show an error message.
            }
          })
      }
    }
  }, [refreshDashboard])

  useEffect(() => {
    async function fetchData() {
      try {
        let array = []
        db.collection('project')
          .doc(`${dashboardId}`)
          .collection('widget')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              array.push({
                name: doc.data().name,
                id: doc.id,
                setting: doc.data().setting,
              })
            })
          })
          .then(() => {
            array.sort((a, b) => {
              const nameA = a.name.toLowerCase()
              const nameB = b.name.toLowerCase()
              if (nameA < nameB) return -1
              if (nameA > nameB) return 1
              return 0
            })

            setDashboardList(array)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [dashboardId, periodType, refreshDashboard])

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  // console.log(customStartDate)
  return (
    <>
      <div ref={ref}>
        <br></br>
        <div className='container'>
          <div className='row'>
            <div className='col-6 d-flex justify-content-start"'>
              <ProjectButton
                dashboardId={dashboardId}
                setDashboardId={setDashboardId}
                dashboardName={dashboardName}
                setDashboardName={setDashboardName}
              ></ProjectButton>
              &nbsp;&nbsp;
              <PeriodButton
                triggerEffectCustom={triggerEffectCustom}
                setTriggerEffectCustom={setTriggerEffectCustom}
                periodType={periodType}
                setPeriodType={setPeriodType}
                customStartDate={customStartDate}
                setCustomStartDate={setCustomStartDate}
                customStopDate={customStopDate}
                setCustomStopDate={setCustomStopDate}
              ></PeriodButton>
            </div>

            <div className='col-6 d-flex justify-content-end'>
              <Link to={`/create-widget/${dashboardId}`} className='btn btn-primary '>
                <i className='fa-solid fa-plus fs-4 me-2'></i>
                Create Widget
              </Link>
              &nbsp;&nbsp;
              {dashboardName !== 'Select Dashboard' ? (
                <EditProject
                  dashboardId={dashboardId}
                  setDashboardId={setDashboardId}
                  dashboardName={dashboardName}
                  setDashboardName={setDashboardName}
                  setRefreshDashboard={setRefreshDashboard}
                  refreshDashboard={refreshDashboard}
                  getImage={downloadScreenshot}
                  periodType={periodType}
                  customStartDate={customStartDate}
                  customStopDate={customStopDate}
                  // widgetBorderColor={widgetBorderColor}
                  // setWidgetBorderColor={setWidgetBorderColor}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {/* <PeriodButton periodType={periodType} setPeriodType={setPeriodType}></PeriodButton> */}
        {/* &nbsp; */}
        {/* <h1>{dashboardName}</h1> */}
        {/* <h1>{periodType}</h1> */}
        <br></br>
        {/* <h1>{Math.floor(customStartDate.getTime() / 1000)}</h1> */}
        {/* <h1>{Math.floor(customStopDate.getTime() / 1000)}</h1> */}
        <br></br>

        <div className='container'>
          <div className='row g-5 g-xl-8'>
            {dashboardList.map((item) => (
              <MyChart
                name={item.name}
                setting={item.setting}
                widgetId={item.id}
                dashboardId={dashboardId}
                key={item.id}
                periodType={periodType}
                customStartDate={customStartDate}
                customStopDate={customStopDate}
                triggerEffectCustom={triggerEffectCustom}
                setTriggerEffectCustom={setTriggerEffectCustom}
                setRefreshDashboard={setRefreshDashboard}
                refreshDashboard={refreshDashboard}
                // widgetBorderColor={widgetBorderColor}
              />
            ))}
          </div>
        </div>

        {/* <div className='col-xl-8'> */}
        {/* <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-3'>
            <TilesWidget1 className='card-xl-stretch' />
          </div>
          <div className='col-xl-9'>
            <TilesWidget4 className='card-xl-stretch' />
          </div>
        </div> */}
        {/* <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-6'>
            <TilesWidget2 className='card-xl-stretch mb-5 mb-xl-8' />
            <div className='row gx-5 gx-xl-8'>
              <div className='col-xl-6'>
                <TilesWidget5
                  className='card-xxl-stretch bg-primary'
                  svgIcon='element-11'
                  titleClass='text-white'
                  descriptionClass='text-white'
                  iconClass='text-white'
                  title='790'
                  description='New Products'
                />
              </div>
              <div className='col-xl-6'>
                <TilesWidget5
                  className='card-xxl-stretch bg-body'
                  svgIcon='rocket'
                  titleClass='text-dark'
                  descriptionClass='text-muted'
                  iconClass='text-success'
                  title='8,600'
                  description='New Customers'
                />
              </div>
            </div>
          </div>
          <div className='col-xl-6'>
            <TilesWidget3 className='card-xl-stretch mb-5 mb-xl-8' />
          </div>
        </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* begin::Row */}
        {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <MixedWidget2
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            strokeColor='#cb1e46'
          />
        </div>
        <div className='col-xxl-4'>
          <ListsWidget5 className='card-xxl-stretch' />
        </div>
        <div className='col-xxl-4'>
          <MixedWidget10
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
          <MixedWidget11
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
          />
        </div>
      </div> */}
        {/* end::Row */}
        {/* begin::Row */}
        {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
        {/* end::Row */}
        {/* begin::Row */}
        {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
        {/* end::Row */}
      </div>
    </>
  )
}
