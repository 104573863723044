import React, {useEffect} from 'react'
import * as echarts from 'echarts/lib/echarts.js'
import {useState} from 'react'
import ReactEcharts from 'echarts-for-react'
import firebase from '../../firebase/FirebaseConfig'
import {fetchLogs, getLogDay} from './ChartCalculationFunction/fetchLogs'
import {Card} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {Dropdown} from 'react-bootstrap'

import './Card.css'

import MyChartEditWidget from './MyChartEditWidget'
const db = firebase.firestore()

function MyChart(props) {
  // console.log(props)
  const {mode} = useThemeMode()
  const [output, setOutput] = useState('')
  const [input, setInput] = useState('')

  const [chartPercent, setChartPercent] = useState('')
  const [colorGauge, setColorGauge] = useState('#009ef7')
  const [isOpen, setIsOpen] = useState(false)
  const [color, setColor] = useState('')

  const [showModalEditWidget, setShowModalEditWidget] = useState(false)
  const [widgetId, setWidgetId] = useState('')
  const [getDataWidget, setGetDataWidget] = useState('')

  const [lastUpdate, setLastUpdate] = useState('')

  const [countDoc, setCountDoc] = useState(0)

  useEffect(() => {
    // console.log(mode)
    if (mode === 'dark') {
      setColor('#ffffff')
    } else {
      setColor('#071437')
    }
  }, [mode])
  // console.log(color)
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    // Initialize ECharts chart
    const chart = echarts.init(document.getElementById(`chart-container-${props.name}`), null, {
      devicePixelRatio: window.devicePixelRatio || 1,
    })

    const options = {
      grid: {
        width: '100%',
        height: '100%',
        borderWidth: 2,
      },
      series: [
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 185,
          endAngle: -9,

          splitNumber: 4,
          itemStyle: {
            color: colorGauge,
          },
          progress: {
            show: true,
            width: 9,
          },

          pointer: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 9,
            },
          },
          axisTick: {
            show: false,
            lineStyle: {
              width: 2,
              color: '#999',
            },
          },
          splitLine: {
            distance: -15,
            length: 5,
            lineStyle: {
              width: 2,
              color: '#999',
            },
          },
          axisLabel: {
            show: false,
            distance: -20,
            color: '#999',
            fontSize: 20,
          },

          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            width: '80%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, '0%'],
            fontSize: 20,
            fontWeight: 'bolder',
            formatter: `{value} %`,
            color: color,
          },
          data: [
            {
              value: chartPercent,
            },
          ],
        },
      ],
    }

    chart.setOption(options)

    return () => {
      chart.dispose()
    }
  }, [props.name, chartPercent, color])

  useEffect(() => {
    const fetchData = async () => {
      if (props.periodType === 'today') {
        const setting = props.setting
        const input_parameter = setting.input.parameter_id
        const output_parameter = setting.output.parameter_id

        const midnight = new Date()
        midnight.setHours(0, 0, 0, 0)
        const start_timestamp = Math.floor(midnight.getTime() / 1000)
        const next_timestamp = start_timestamp + (86400 - 1)

        // const list_log_input = await fetchLogs(input_parameter, start_timestamp, next_timestamp)
        // const list_log_output = await fetchLogs(output_parameter, start_timestamp, next_timestamp)

        let count_sec_input = 0
        // if (setting.input.calculation === 'event_counter') {
        //   list_log_input.sort((a, b) => a.t - b.t)
        //   const min_condition = setting.input.min_condition
        //   const max_condition = setting.input.max_condition

        //   // Condition 1 : min_condition !== 'inf' && max_condition == 'inf'
        //   if (min_condition !== 'inf' && max_condition == 'inf') {
        //     count_sec_input = await getSecondOfDataLog(list_log_input, min_condition, max_condition)
        //   }
        // }

        let count_sec_output = 0
        // if (setting.input.calculation === 'event_counter') {
        //   list_log_output.sort((a, b) => a.t - b.t)
        //   const min_condition = setting.output.min_condition
        //   const max_condition = setting.output.max_condition

        //   // Condition 1 : min_condition !== 'inf' && max_condition == 'inf'
        //   if (min_condition !== 'inf' && max_condition == 'inf') {
        //     count_sec_output = await getSecondOfDataLog(
        //       list_log_output,
        //       min_condition,
        //       max_condition
        //     )
        //   }
        // }

        //  NEW Get Log //

        // console.log('widgetId : ', props.widgetId)
        // console.log('start_timestamp : ', start_timestamp)
        // console.log('next_timestamp : ', next_timestamp)

        const array_input = await getLogDay(
          props.widgetId,
          props.dashboardId,
          start_timestamp,
          next_timestamp
        )

        // console.log(props.name, ' : ', array_input)

        count_sec_input = array_input.count_sec_input
        count_sec_output = array_input.count_sec_output

        // console.log(array_input.last_update)
        // // Show Debug
        // const timestampInSeconds = array_input.last_update // Replace this with your timestamp
        // const date = new Date(timestampInSeconds * 1000)
        // const month = date.toLocaleString('en-US', {month: 'short'})
        // const day = date.getDate()
        // const hours = date.getHours()
        // const minutes = date.getMinutes()
        // const formattedDate = `${month} ${day}, ${hours.toString().padStart(2, '0')}:${minutes
        //   .toString()
        //   .padStart(2, '0')}`
        // setLastUpdate(formattedDate)
        // //  End show Debug

        //  Stop New Get Log //

        const percent = ((count_sec_output / count_sec_input) * 100).toFixed(2)
        // console.log(percent)
        setChartPercent(percent)
        setOutput(setting.output.name + ' ' + (await secondsToHours(count_sec_output)) + '')
        setInput(setting.input.name + ' ' + (await secondsToHours(count_sec_input)) + '')

        if (props.setting.status_zone !== undefined) {
          const value_status_zone = props.setting.status_zone

          if (value_status_zone.enable == true) {
            if (percent <= value_status_zone.unsatisfied) {
              // console.log('1')
              setColorGauge('#f1416c')
            } else if (percent >= value_status_zone.satisfied) {
              // console.log('2')

              setColorGauge('#36E71E')
            } else if (
              percent > value_status_zone.unsatisfied &&
              percent < value_status_zone.satisfied
            ) {
              // console.log('3')
              setColorGauge('#0087F7')
            }
          } else {
            setColorGauge('#0087F7')
          }

          // console.log(colorGauge)
        }

        // console.log(props.name, ' : ', Object.keys(array_input).length === 0)
        // If true is mean widget not have data or just create widget
      } else if (props.periodType === 'custom') {
        // console.log(props.customStartDate)
        // console.log(props.customStopDate)
        const midnight_startDate = new Date(props.customStartDate)
        const midnight_stopDate = new Date(props.customStopDate)
        midnight_startDate.setHours(0, 0, 0, 0)
        midnight_stopDate.setHours(23, 59, 59, 0)
        // console.log('midnight_startDate :', midnight_startDate)
        // console.log('midnight_stopDate :', midnight_stopDate)

        const midnight_start_timestamp = Math.floor(midnight_startDate.getTime() / 1000)
        const midnight_stopDate_timestamp = Math.floor(midnight_stopDate.getTime() / 1000)

        // console.log('midnight_start_timestamp :', midnight_start_timestamp)
        // console.log('midnight_stopDate_timestamp :', midnight_stopDate_timestamp)

        const setting = props.setting
        const input_parameter = setting.input.parameter_id
        const output_parameter = setting.output.parameter_id

        // console.log(props.widgetId)
        // console.log(props.dashboardId)

        let count_sec_input = 0
        let count_sec_output = 0
        async function fetchLogData() {
          try {
            const logDayCollection = await db
              .collection('project')
              .doc(props.dashboardId)
              .collection('widget')
              .doc(props.widgetId)
              .collection('log_day2')
              .where('start_timestamp', '>=', midnight_start_timestamp)
              .where('start_timestamp', '<=', midnight_stopDate_timestamp)
              .orderBy('start_timestamp', 'desc')
              .get()

            setCountDoc(logDayCollection.size)
            logDayCollection.forEach(async (doc) => {
              count_sec_input += doc.data().count_sec_input
              count_sec_output += doc.data().count_sec_output

              // // Show Debug
              // const timestampInSeconds = doc.data().last_update // Replace this with your timestamp
              // const date = new Date(timestampInSeconds * 1000)
              // const month = date.toLocaleString('en-US', {month: 'short'})
              // const day = date.getDate()
              // const hours = date.getHours()
              // const minutes = date.getMinutes()
              // const formattedDate = `${month} ${day}, ${hours.toString().padStart(2, '0')}:${minutes
              //   .toString()
              //   .padStart(2, '0')}`
              // setLastUpdate(formattedDate)
              // //  End show Debug
            })
          } catch (error) {
            console.error('Error getting documents: ', error)
          }
        }

        await fetchLogData()

        const percent = ((count_sec_output / count_sec_input) * 100).toFixed(2)
        // console.log(percent, count_doc)
        setChartPercent(percent)
        setOutput(setting.output.name + ' ' + (await secondsToHours(count_sec_output)) + '')
        setInput(setting.input.name + ' ' + (await secondsToHours(count_sec_input)) + '')

        if (props.setting.status_zone !== undefined) {
          const value_status_zone = props.setting.status_zone

          if (value_status_zone.enable == true) {
            if (percent <= value_status_zone.unsatisfied) {
              // console.log('1')
              setColorGauge('#f1416c')
            } else if (percent >= value_status_zone.satisfied) {
              // console.log('2')

              setColorGauge('#36E71E')
            } else if (
              percent > value_status_zone.unsatisfied &&
              percent < value_status_zone.satisfied
            ) {
              // console.log('3')
              setColorGauge('#0087F7')
            }
          } else {
            setColorGauge('#0087F7')
          }

          // console.log(colorGauge)
        }
      }
    }

    if (props.triggerEffectCustom) {
      fetchData()
      props.setTriggerEffectCustom(false)
    } else {
      fetchData()
      props.setTriggerEffectCustom(false)
    }
  }, [props.setting, props.triggerEffectCustom])
  // const handleEditWidget = (props) => {
  //   console.log(props.widgetId)
  //   console.log(props.dashboardId)
  // }
  // console.log(props)
  const handleDeleteWidget = (props) => {
    // console.log(props.widgetId)
    // console.log(props.dashboardId)

    Swal.fire({
      title: `Do you want to remove widget \n${props.name} ?`,
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Remove',
      denyButtonText: `Cancel`,
      confirmButtonColor: '#f1416c',
      denyButtonColor: '#0077ba',
      // confirmButtonColor: '#ff5733',
      // denyButtonColor: '#0077ba',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        })

        await db
          .collection('project')
          .doc(props.dashboardId)
          .collection('widget')
          .doc(props.widgetId)
          .delete()
          .then(async () => {
            console.log('Document successfully deleted!')

            await db
              .collection('project')
              .doc(props.dashboardId)
              .collection('widget')
              .doc(props.widgetId)
              .collection('log_day2')
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                  console.log(doc.id)
                  await db
                    .collection('project')
                    .doc(props.dashboardId)
                    .collection('widget')
                    .doc(props.widgetId)
                    .collection('log_day2')
                    .doc(doc.id)
                    .delete()
                    .then(() => {
                      console.log('Document successfully deleted!')
                      Swal.fire('Delete widget!', '', 'success').then((result) => {
                        if (result.isConfirmed) {
                          props.setRefreshDashboard(props.refreshDashboard + 1)
                        }
                      })
                    })
                    .catch((error) => {
                      console.error('Error removing document: ', error)
                      Swal.fire('Error!', '', 'error')
                    })
                })
              })
              .catch((error) => {
                console.log('Error getting documents: ', error)
                Swal.fire('Error!', '', 'error')
              })
          })
          .catch((error) => {
            console.error('Error removing document: ', error)
            Swal.fire('Error!', '', 'error')
          })
      }
    })
  }

  const handleEditWidget = (props) => {
    // console.log(props)
    setShowModalEditWidget(true)
    setWidgetId(props.widgetId)
    setGetDataWidget(props)
  }

  return (
    <Card
      className='col-xl-2 cardChart'
      style={{
        borderRadius: '15px',
        // borderColor: props.widgetBorderColor,
        borderColor: '#dbdfe9',
        borderStyle: 'solid',
        borderWidth: '1px',
        width: '200px',
        height: '200px',
        marginLeft: '10px',
        marginTop: '10px',
      }}
    >
      <div className='row'>
        <center>
          <h4 className='mt-5'>{props.name}</h4>
        </center>

        <div
          id={`chart-container-${props.name}`}
          style={{width: '100%', height: '200px', position: 'relative', top: '-35px'}}
        ></div>
        <center>
          <div
            className='text-inverse-secondary bg-secondary'
            style={{
              width: '120px',
              position: 'relative',
              top: '-90px',
              fontSize: '11px',
              borderRadius: '5px',
              // backgroundColor: '#f3f3f3'
            }}
          >
            {props.periodType === 'custom' && countDoc == 0 ? (
              <>
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                  className='text-dark'
                >
                  Data not found
                </span>
              </>
            ) : (
              <>
                <span className='text-dark'>{output}</span>
                <br />
                <span className='text-dark'> {input}</span>
                <br />
                {/* <span className='text-dark'> {countDoc}</span> */}
              </>
            )}
          </div>

          {/* </div> */}
        </center>

        <div className='me-0' style={{position: 'absolute', left: '68%', top: '5%'}}>
          <div className='dropdown' style={{position: 'absolute'}}>
            <button
              className='btn btn-sm  '
              type='button'
              id='dropdownMenuButton'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded={isOpen}
              data-bs-placement='bottom-end'
              onClick={toggleDropdown}
            >
              <i className='bi bi-three-dots fs-3'></i>
            </button>
            <div
              className={`dropdown-menu ${isOpen ? 'show' : ''}`}
              aria-labelledby='dropdownMenuButton'
              style={{cursor: 'pointer'}}
            >
              <a className='dropdown-item' onClick={() => handleEditWidget(props)}>
                <i className='bi bi-pencil-square me-3'></i>
                Edit
              </a>

              <a className='dropdown-item' onClick={() => handleDeleteWidget(props)}>
                <i className='bi bi-trash me-3'></i>
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
      <MyChartEditWidget
        showModalEditWidget={showModalEditWidget}
        setShowModalEditWidget={setShowModalEditWidget}
        widgetId={widgetId}
        setWidgetId={setWidgetId}
        data={getDataWidget}
        setRefreshDashboard={props.setRefreshDashboard}
        refreshDashboard={props.refreshDashboard}
      ></MyChartEditWidget>
    </Card>
  )
}

export default MyChart

async function getSecondOfDataLog(list_log_input, min_condition, max_condition) {
  let count_sec_input = 0
  for (let index = 0; index < list_log_input.length; index++) {
    const item = list_log_input[index]
    if (item.v >= min_condition && list_log_input[index + 1] !== undefined) {
      const item_next = list_log_input[index + 1]
      count_sec_input += item_next.t - item.t
    }
  }
  return count_sec_input
}

async function secondsToHours(seconds) {
  const hours = seconds / 3600 // 1 hour = 3600 seconds
  return hours.toFixed(1) + ' hr'
}
