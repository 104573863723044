import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import FirebaseAuthService from '../../firebase/FirebaseAuthService'
import firebase from '../../firebase/FirebaseConfig'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import {useState} from 'react'
import {useUser} from './UserContext' // Import the useUser hook
import {Link} from 'react-router-dom'
import CryptoJS from 'crypto-js' // Import crypto-js for encryption
import {PulseLoader} from 'react-spinners'

const firestore = firebase.firestore()

export function Login() {
  const [email, setEmail] = useState('service@dygistech.com')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const {user, setUser} = useUser() // Access user and setUser from context
  const [loading, setLoading] = useState(false)

  const auth = getAuth()

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in successfully
        const user = userCredential.user
        // console.log(user)
        firestore
          .collection('user')
          .where('user_id', '==', user.uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // console.log(doc.id, " => ", doc.data());
              const data = doc.data()
              const encryptedUser_id = data.user_id
              const userInfo = {
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                image_profile: data.image_profile,
                type: data.type,
                telephone: data?.telephone,
                jobPosition: data?.jobPosition,
                company: data?.company,
                address: data?.address,
                user_id: CryptoJS.AES.encrypt(encryptedUser_id, 'encryptionSecretKey').toString(),
              }
              setUser(userInfo);
              setLoading(false)
            })
          })
          .catch((error) => {
            // Handle login errors
            const errorMessage = error.message
            setError(errorMessage)
          })
        // Update the user data in the context
      })
      .catch((error) => {
        // Handle login errors
        const errorMessage = error.message
        setError(errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Container>
      <Row className='d-flex justify-content-center' style={{fontSize: 30, fontWeight: 'bold'}}>
        Sign In
      </Row>

      <Row className='justify-content-center mt-5'>
        <Col md={12}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='email' className='mt-5'>
              <Form.Label>Email address:</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onInvalid={(e) => e.target.setCustomValidity('Please enter email')}
                required
              />
            </Form.Group>
            <Form.Group controlId='password' className='mt-5'>
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onInvalid={(e) => e.target.setCustomValidity('Please enter password')}
                required
              />
            </Form.Group>
            <div className='d-flex justify-content-end'>
              <Link to='/auth/forgot-password' className='link-primary'>
                Reset Password ?
              </Link>
            </div>
            <Button
              variant='primary'
              type='submit'
              className='my-10'
              style={{width: '100%'}}
              disabled={loading} // Disable the button while loading
            >
              {loading ? <PulseLoader color='white' size={10} /> : 'Login'}
            </Button>
          </Form>
          {error && <p className='text-danger mt-3'>{error}</p>}
          {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
            Not a Member yet?{' '}
            <Link to='/auth/registration' className='link-primary'>
              Sign up
            </Link>
          </div> */}
        </Col>
      </Row>
    </Container>
  )
}
