import React, {useState, useEffect} from 'react'
import {Dropdown, DropdownButton, ButtonGroup, Modal, Button, Form} from 'react-bootstrap'
import Firebase from '../../firebase/FirebaseConfig'
import {useUser} from '../../modules/newauth/UserContext'
import {Link, useParams} from 'react-router-dom'
import firebase from '../../firebase/FirebaseConfig'
import CryptoJS from 'crypto-js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {AddParameter} from '../create-widget/AddParameter'
import {async} from 'q'
function MyChartEditWidget(props) {
  const {user} = useUser()
  const MySwal = withReactContent(Swal)
  const [showModalOutput, setShowModalOutput] = useState(false)
  const [showModalInput, setShowModalInput] = useState(false)

  const [dataParameterOutput, setDataParameterOutput] = useState([])
  const [dataParameterInput, setDataParameterInput] = useState([])

  const [showChartOutput, setShowChartOutput] = useState(false)
  const [showChartInput, setShowChartInput] = useState(false)

  const [widgetData, setWidgetData] = useState({
    widgetName: '',
    outputName: '',
    parameterOutput: '',
    calculationOutput: 'none',
    minConditionOutput: '',
    maxConditionOutput: '',
    inputName: '',
    parameterInput: '',
    calculationInput: 'none',
    minConditionInput: '',
    maxConditionInput: '',
    multiply: '',
    suffix: '',
    statusZone: false,
    unsatisfied: '',
    satisfied: '',
    normal: '',
    idParameterOutput: '',
    idParameterInput: '',
  })

  const dotStyleGreen = {
    width: '15px',
    height: '15px',
    backgroundColor: '#50cd89',
    borderRadius: '50%',
  }

  const dotStyleRed = {
    width: '15px',
    height: '15px',
    backgroundColor: '#f1416c',
    borderRadius: '50%',
  }

  const dotStyleBlue = {
    width: '15px',
    height: '15px',
    backgroundColor: '#009ef7',
    borderRadius: '50%',
  }

  function VerticalSeparator() {
    const separatorStyles = {
      borderLeft: '2px solid #ccc',
      height: '100%',
      margin: '0 10px',
    }

    return <div style={separatorStyles}></div>
  }

  const db = firebase.firestore()

  const addParameterOutput = () => {
    setShowModalOutput(true)
  }

  const closeModalOutput = () => {
    setShowModalOutput(false)
  }

  const addParameterInput = () => {
    setShowModalInput(true)
  }

  const closeModalInput = () => {
    setShowModalInput(false)
  }

  const handleCheckboxChange = (event) => {
    setWidgetData({
      ...widgetData,
      statusZone: event.target.checked,
    })
  }

  const closeModal = () => {
    props.setShowModalEditWidget(false)
  }

  useEffect(() => {
    if (!props.data) {
      return
    }

    const {
      name,
      setting: {output, input, multiply, suffix, status_zone},
    } = props.data

    if (output && output.parameter_id) {
      const promises = []

      // Fetch output parameter
      const outputPromise = db
        .collection('parameter')
        .where('parameter_id', '==', output.parameter_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const parameterOutput = doc.data().name || ''
            setWidgetData((prevData) => ({
              ...prevData,
              parameterOutput,
            }))
          })
        })
        .catch((error) => {
          console.error('Error fetching output parameter:', error)
        })
      promises.push(outputPromise)

      if (input && input.parameter_id) {
        // Fetch input parameter
        const inputPromise = db
          .collection('parameter')
          .where('parameter_id', '==', input.parameter_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const parameterInput = doc.data().name || ''
              setWidgetData((prevData) => ({
                ...prevData,
                parameterInput,
              }))
            })
          })
          .catch((error) => {
            console.error('Error fetching input parameter:', error)
          })
        promises.push(inputPromise)
      }

      // Wait for all promises to resolve before updating the state
      Promise.all(promises).then(() => {
        setWidgetData((prevData) => ({
          ...prevData,
          widgetName: name,
          outputName: output.name || '',
          calculationOutput: output.calculation || 'none',
          minConditionOutput: output.min_condition || '',
          maxConditionOutput: output.max_condition || '',
          inputName: input?.name || '',
          calculationInput: input?.calculation || 'none',
          minConditionInput: input?.min_condition || '',
          maxConditionInput: input?.max_condition || '',
          // multiply: multiply || '',
          multiply: '100',
          // suffix: suffix || '',
          suffix: '%',
          statusZone: status_zone?.enable || false,
          unsatisfied: status_zone?.unsatisfied || '',
          satisfied: status_zone?.satisfied || '',
          normal: status_zone?.normal || '',
          idParameterInput: input.parameter_id,
          idParameterOutput: output.parameter_id,
        }))
      })
    }
  }, [props.data])

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  const submitModalOutput = () => {
    // console.log(dataParameterOutput)

    dataParameterOutput.forEach((data, index) => {
      // console.log(data.name)

      const parameterOutput = data.name
      const idParameterOutput = data.parameter_id
      setWidgetData((prevData) => ({
        ...prevData,
        parameterOutput,
        idParameterOutput,
      }))
    })

    closeModalOutput()
  }

  const submitModalInput = () => {
    // console.log(dataParameterInput)

    dataParameterInput.forEach((data, index) => {
      // console.log(data.name)

      const parameterInput = data.name
      const idParameterInput = data.parameter_id
      setWidgetData((prevData) => ({
        ...prevData,
        parameterInput,
        idParameterInput,
      }))
    })

    closeModalInput()
  }

  useEffect(() => {
    // Calculate the "Normal" value when "unsatisfied," "satisfied," or "statusZone" changes
    if (widgetData.unsatisfied !== '' && widgetData.satisfied !== '' && widgetData.statusZone) {
      const normalValue = `${widgetData.unsatisfied + 1}-${widgetData.satisfied - 1}` // Calculate the "Normal" value here
      setWidgetData((prevData) => ({
        ...prevData,
        normal: normalValue,
      }))
    } else {
      setWidgetData((prevData) => ({
        ...prevData,
        normal: '',
      }))
    }
  }, [widgetData.unsatisfied, widgetData.satisfied, widgetData.statusZone])

  const submitEditDashboard = async (props) => {
    const widgetId = props.widgetId
    const dashboardId = props.data.dashboardId
    const widgetDocRef = db
      .collection('project')
      .doc(dashboardId)
      .collection('widget')
      .doc(widgetId)

    try {
      const widgetDoc = await widgetDocRef.get()

      if (widgetDoc.exists) {
        const docData = widgetDoc.data()
        const hasChanged =
          widgetData.calculationOutput !== docData.setting.output.calculation ||
          widgetData.minConditionOutput !== docData.setting.output.min_condition ||
          widgetData.maxConditionOutput !== docData.setting.output.max_condition ||
          widgetData.idParameterOutput !== docData.setting.output.parameter_id ||
          widgetData.calculationInput !== docData.setting.input.calculation ||
          widgetData.minConditionInput !== docData.setting.input.min_condition ||
          widgetData.maxConditionInput !== docData.setting.input.max_condition ||
          widgetData.idParameterInput !== docData.setting.input.parameter_id

        if (hasChanged) {
          console.log('Is change')

          const logDayCollectionRef = db
            .collection('project')
            .doc(dashboardId)
            .collection('widget')
            .doc(widgetId)
            .collection('log_day2')
          const querySnapshot = await logDayCollectionRef.get()

          querySnapshot.forEach(async (doc) => {
            await doc.ref.delete()
          })

          const updatedData = {
            name: widgetData.widgetName,
            order: '',
            setting: {
              input: {
                calculation: widgetData.calculationInput,
                max_condition: widgetData.maxConditionInput,
                min_condition: widgetData.minConditionInput,
                name: widgetData.inputName,
                parameter_id: widgetData.idParameterInput,
              },
              output: {
                calculation: widgetData.calculationOutput,
                max_condition: widgetData.maxConditionOutput,
                min_condition: widgetData.minConditionOutput,
                name: widgetData.outputName,
                parameter_id: widgetData.idParameterOutput,
              },
              status_zone: {
                enable: widgetData.statusZone,
                normal: widgetData.normal,
                satisfied: widgetData.satisfied,
                unsatisfied: widgetData.unsatisfied,
              },
              multiply: widgetData.multiply,
              suffix: widgetData.suffix,
            },
          }

          await widgetDocRef.update(updatedData)

          Swal.fire('Widget updated!', '', 'success').then((result) => {
            if (result.isConfirmed) {
              // window.location.reload()
              props.setRefreshDashboard(props.refreshDashboard + 1)
              props.setShowModalEditWidget(false)
            }
          })
        } else {
          console.log('Not change')
          const updatedData = {
            name: widgetData.widgetName,
            order: '',
            setting: {
              input: {
                calculation: widgetData.calculationInput,
                max_condition: widgetData.maxConditionInput,
                min_condition: widgetData.minConditionInput,
                name: widgetData.inputName,
                parameter_id: widgetData.idParameterInput,
              },
              output: {
                calculation: widgetData.calculationOutput,
                max_condition: widgetData.maxConditionOutput,
                min_condition: widgetData.minConditionOutput,
                name: widgetData.outputName,
                parameter_id: widgetData.idParameterOutput,
              },
              status_zone: {
                enable: widgetData.statusZone,
                normal: widgetData.normal,
                satisfied: widgetData.satisfied,
                unsatisfied: widgetData.unsatisfied,
              },
              multiply: widgetData.multiply,
              suffix: widgetData.suffix,
            },
          }

          await widgetDocRef.update(updatedData)

          Swal.fire('Widget updated!', '', 'success').then((result) => {
            if (result.isConfirmed) {
              // window.location.reload()
              props.setRefreshDashboard(props.refreshDashboard + 1)
              props.setShowModalEditWidget(false)
            }
          })
        }
      } else {
        console.log('Widget document not found.')
      }
    } catch (error) {
      console.error('Error fetching widget data:', error)
    }
  }

  return (
    <>
      <Modal show={props.showModalEditWidget} onHide={closeModal} size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Widget</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                {/* <div className='card card-custom card-stretch-100 shadow mb-5'>
              <div className='card-body'> */}
                <div className='row d-flex justify-content-center align-items-center mb-10'>
                  <div className='col-6 col-lg-1 d-flex align-items-center'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      value='Name'
                      style={{color: 'inherit', textAlign: 'center', borderRadius: '10px'}}
                      disabled
                    />
                  </div>
                  <div className='col-6 col-lg-3'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      value={widgetData.widgetName}
                      onChange={(e) => setWidgetData({...widgetData, widgetName: e.target.value})}
                    />
                  </div>
                </div>

                <div className='row d-flex justify-content-around'>
                  {/* div left */}
                  <div className='col-12 col-lg-5'>
                    <div className='row mb-5 '>
                      <div class='form-group row'>
                        <label className='form-label col-4 mt-2'>Output Name</label>
                        <div class='col-sm-8'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputPassword2'
                            value={widgetData.outputName}
                            onChange={(e) =>
                              setWidgetData({...widgetData, outputName: e.target.value})
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-4 mt-2'>Parameter</label>

                        <div className='col-12 col-sm-8'>
                          <div className='input-group'>
                            <input
                              type='text'
                              className='form-control form-control-sm'
                              id='inputPassword'
                              value={widgetData.parameterOutput}
                              onChange={(e) =>
                                setWidgetData({...widgetData, parameterOutput: e.target.value})
                              }
                            />
                            <div className='input-group-append'>
                              <button
                                className='btn btn-primary btn-sm'
                                type='button'
                                onClick={addParameterOutput}
                              >
                                <i className='fa-solid fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <Modal
                          show={showModalOutput}
                          onHide={closeModalOutput}
                          animation={true}
                          size='lg'
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Output Parameter</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <AddParameter
                              selectedRows={dataParameterOutput}
                              setSelectedRows={setDataParameterOutput}
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant='secondary' onClick={closeModalOutput}>
                              Close
                            </Button>
                            <Button variant='primary' onClick={submitModalOutput}>
                              Submit
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-4 mt-2'>Calculation</label>
                        <div class='col-sm-8'>
                          <select
                            className='form-select form-select-sm'
                            aria-label='Select example'
                            value={widgetData.calculationOutput}
                            onChange={(e) =>
                              setWidgetData({...widgetData, calculationOutput: e.target.value})
                            }
                          >
                            <option value='none'>None</option>
                            {/* <option value='value'>Value</option>
                            <option value='differential'>Differential</option> */}
                            <option value='event_counter'>Event Counter</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <div className='form-label col-4 mt-2'></div>

                        <label className='form-label col-4 mt-2'>Min condition</label>
                        <div class='col-sm-4'>
                          <input
                            type='text'
                            className='form-control form-control-sm col-sm-3'
                            id='inputPassword'
                            value={widgetData.minConditionOutput}
                            onChange={(e) =>
                              setWidgetData({...widgetData, minConditionOutput: e.target.value})
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-7'>
                      <div class='form-group row'>
                        <div className='form-label col-4 mt-2'></div>

                        <label className='form-label col-4 mt-2'>Max condition</label>
                        <div class='col-sm-4'>
                          <input
                            type='text'
                            className='form-control form-control-sm col-sm-3'
                            id='inputPassword'
                            value={widgetData.maxConditionOutput}
                            onChange={(e) =>
                              setWidgetData({...widgetData, maxConditionOutput: e.target.value})
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* {widgetData.idParameterOutput !== '' ? (
                      <p style={{cursor: 'pointer', color: '#009ef7'}} onClick={handleShowChart}>
                        {showChartOutput == true ? 'Hide' : 'Show'}
                      </p>
                    ) : (
                      ''
                    )}

                    {widgetData.idParameterOutput !== '' && showChartOutput == true ? (
                      <ChartParameterData
                        parameterId={widgetData.idParameterOutput}
                        minConditionOutput={widgetData.minConditionOutput}
                        maxConditionOutput={widgetData.maxConditionOutput}
                      ></ChartParameterData>
                    ) : (
                      ''
                    )} */}

                    <hr className='col-12' />

                    <div className='row mb-5 mt-7'>
                      <div class='form-group row'>
                        <label className='form-label col-4 mt-2'>Input Name</label>
                        <div class='col-sm-8'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputPassword'
                            value={widgetData.inputName}
                            onChange={(e) =>
                              setWidgetData({...widgetData, inputName: e.target.value})
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-4 mt-2'>Parameter</label>

                        <div className='col-12 col-sm-8'>
                          <div className='input-group'>
                            <input
                              type='text'
                              className='form-control form-control-sm'
                              id='inputPassword'
                              value={widgetData.parameterInput}
                              onChange={(e) =>
                                setWidgetData({...widgetData, parameterInput: e.target.value})
                              }
                            />
                            <div className='input-group-append'>
                              <button
                                className='btn btn-primary btn-sm'
                                type='button'
                                onClick={addParameterInput}
                              >
                                <i className='fa-solid fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <Modal
                          show={showModalInput}
                          onHide={closeModalInput}
                          animation={true}
                          size='lg'
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Input Parameter</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <AddParameter
                              selectedRows={dataParameterInput}
                              setSelectedRows={setDataParameterInput}
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant='secondary' onClick={closeModalInput}>
                              Close
                            </Button>
                            <Button variant='primary' onClick={submitModalInput}>
                              Submit
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-4 mt-2'>Calculation</label>
                        <div class='col-sm-8'>
                          <select
                            className='form-select form-select-sm'
                            aria-label='Select example'
                            value={widgetData.calculationInput}
                            onChange={(e) =>
                              setWidgetData({...widgetData, calculationInput: e.target.value})
                            }
                          >
                            <option value='none'>None</option>
                            {/* <option value='value'>Value</option>
                            <option value='differential'>Differential</option> */}
                            <option value='event_counter'>Event Counter</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <div className='form-label col-4 mt-2'></div>

                        <label className='form-label col-4 mt-2'>Min condition</label>
                        <div class='col-sm-4'>
                          <input
                            type='text'
                            className='form-control form-control-sm col-sm-3'
                            id='inputPassword'
                            value={widgetData.minConditionInput}
                            onChange={(e) =>
                              setWidgetData({...widgetData, minConditionInput: e.target.value})
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <div className='form-label col-4 mt-2'></div>

                        <label className='form-label col-4 mt-2'>Max condition</label>
                        <div class='col-sm-4'>
                          <input
                            type='text'
                            className='form-control form-control-sm col-sm-3'
                            id='inputPassword'
                            value={widgetData.maxConditionInput}
                            onChange={(e) =>
                              setWidgetData({...widgetData, maxConditionInput: e.target.value})
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* {widgetData.idParameterInput !== '' ? (
                      <p
                        style={{cursor: 'pointer', color: '#009ef7'}}
                        onClick={handleShowChartInput}
                      >
                        {showChartInput == true ? 'Hide' : 'Show'}
                      </p>
                    ) : (
                      ''
                    )}

                    {widgetData.idParameterInput !== '' && showChartInput == true ? (
                      <ChartParameterData
                        parameterId={widgetData.idParameterInput}
                        minConditionOutput={widgetData.minConditionInput}
                        maxConditionOutput={widgetData.maxConditionInput}
                      ></ChartParameterData>
                    ) : (
                      ''
                    )} */}
                  </div>
                  <div className='col-1 d-flex justify-content-center align-items-center'>
                    <VerticalSeparator />
                  </div>

                  {/* div right */}
                  <div className='col-12 col-lg-5'>
                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-3 mt-2'>Multiply</label>
                        <div class='col-sm-5'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputPassword'
                            value={widgetData.multiply}
                            onChange={(e) =>
                              setWidgetData({...widgetData, multiply: e.target.value})
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-3 mt-2'>Suffix</label>
                        <div class='col-sm-5'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputPassword'
                            value={widgetData.suffix}
                            onChange={(e) => setWidgetData({...widgetData, suffix: e.target.value})}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-16'></div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-3 mt-2'>Status Zone</label>
                        <div class='col-sm-5'>
                          <div className='form-check form-switch form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='flexSwitchDefault'
                              checked={widgetData.statusZone} // Set the checked status from state
                              onChange={handleCheckboxChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-3 mt-2'>Unsatisfied</label>
                        <div class='col-sm-5'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputPassword'
                            disabled={!widgetData.statusZone}
                            value={widgetData.statusZone ? widgetData.unsatisfied : ''}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value)
                              if (!isNaN(newValue)) {
                                // Ensure the value is an integer
                                setWidgetData({
                                  ...widgetData,
                                  unsatisfied: newValue,
                                })
                              } else {
                                // Handle invalid input (e.g., non-numeric values)
                                setWidgetData({
                                  ...widgetData,
                                  unsatisfied: '', // Clear the input if it's not a valid number
                                })
                              }
                            }}
                          />
                        </div>
                        <div class='col-sm-1 mt-3' style={{textAlign: 'center'}}>
                          <div style={dotStyleRed}></div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div class='form-group row'>
                        <label className='form-label col-3 mt-2'>Satisfied</label>
                        <div class='col-sm-5'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputPassword'
                            disabled={!widgetData.statusZone}
                            value={widgetData.statusZone ? widgetData.satisfied : ''}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value)

                              if (!isNaN(newValue)) {
                                // Ensure the value is an integer
                                setWidgetData({
                                  ...widgetData,
                                  satisfied: newValue,
                                })
                              } else {
                                // Handle invalid input (e.g., non-numeric values)
                                setWidgetData({
                                  ...widgetData,
                                  satisfied: '', // Clear the input if it's not a valid number
                                })
                              }
                            }}
                          />
                        </div>
                        <div class='col-sm-1 mt-3' style={{textAlign: 'center'}}>
                          <div style={dotStyleGreen}></div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-20'>
                      <div class='form-group row'>
                        <label className='form-label col-3 mt-2'>Normal (Auto)</label>
                        <div class='col-sm-5'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputPassword'
                            disabled
                            value={widgetData.normal !== '' ? `${widgetData.normal}` : ''}
                            onChange={(e) => setWidgetData({...widgetData, normal: e.target.value})}
                          />
                        </div>
                        <div class='col-sm-1 mt-3' style={{textAlign: 'center'}}>
                          <div style={dotStyleBlue}></div>
                        </div>
                      </div>
                    </div>
                    <br />
                    {/* <div className='d-flex justify-content-end'>
                      <div className='mt-20'>
                        <a href='#' className='btn btn-secondary btn-sm'>
                          Cancel
                        </a>
                        &nbsp;&nbsp;
                        <a href='#' className='btn btn-primary btn-sm'>
                          Submit
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div>
      </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary ' onClick={() => submitEditDashboard(props)}>
            Submit
          </Button>

          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MyChartEditWidget
