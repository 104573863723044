import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'

import FirebaseAuthService from '../../firebase/FirebaseAuthService'

export function Logout() {
  const {logout} = useAuth()
  useEffect(() => {
    FirebaseAuthService.logoutUser()
    logout()

    // document.location.reload()
    localStorage.removeItem('dashboardId')
    localStorage.removeItem('periodType')

    window.location.href = '/auth/login'
    
  }, [logout])

  return (
    <></>
    // <Routes>
    //   <Navigate to='/auth/login' />
    // </Routes>
  )
}
