import {useEffect} from 'react'
import {useState} from 'react'
import firebase from '../../firebase/FirebaseConfig'
import {useUser} from '../../modules/newauth/UserContext'
import Swal from 'sweetalert2'
import CryptoJS from 'crypto-js'
import {Dropdown, DropdownButton, ButtonGroup, Modal, Button, Form} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {PulseLoader} from 'react-spinners'
import {fetchLogs, fetchLogsCustom, getLogDay} from './ChartCalculationFunction/fetchLogs'

export function EditProject(props) {
  // console.log(props)
  const [isOpen, setIsOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [dashboardName, setDashboardName] = useState(props.dashboardName || '-')

  const [loading, setLoading] = useState(false)
  const [arrayCsv, setArrayCsv] = useState([])

  const {user} = useUser()
  const db = firebase.firestore()

  const handleDashboardNameChange = (event) => {
    setDashboardName(event.target.value)
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleEditWidget = (props) => {
    setShowModal(true)
    setDashboardName(props.dashboardName)
    // console.log(props)
  }

  const closeModal = () => {
    setShowModal(false)
    // setDashboardNameNew('')
  }

  const submitEditDashboard = (props) => {
    setShowModal(false)

    const updatedData = {
      name: dashboardName,
      // Add other fields you want to update
    }

    db.collection('project')
      .doc(props.dashboardId)
      .update(updatedData)
      .then(() => {
        console.log('Document successfully deleted!')
        Swal.fire('Saved!', '', 'success').then((result) => {
          if (result.isConfirmed) {
            props.setRefreshDashboard(props.refreshDashboard + 1)
          }
        })
      })
      .catch((error) => {
        console.error('Error removing document: ', error)
        Swal.fire('Error!', '', 'error')
      })
  }
  //   console.log(dashboardName)

  const handleDeleteDashboard = (props) => {
    Swal.fire({
      title: `Do you want to remove dashboard \n${props.dashboardName} ?`,
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Remove',
      denyButtonText: `Cancel`,
      // confirmButtonColor: '#ff5733',
      // denyButtonColor: '#34a4eb',
      confirmButtonColor: '#f1416c',
      denyButtonColor: '#0077ba',
    }).then((result) => {
      if (result.isConfirmed) {
        db.collection('project')
          .doc(props.dashboardId)
          .delete()
          .then(() => {
            console.log('Document successfully deleted!')
            Swal.fire('Delete widget!', '', 'success').then((result) => {
              if (result.isConfirmed) {
                props.setRefreshDashboard(props.refreshDashboard + 1)
              }
            })
          })
          .catch((error) => {
            console.error('Error removing document: ', error)
            Swal.fire('Error!', '', 'error')
          })
      }
    })
  }

  const handleExportCSV = async (props) => {
    // console.log(props)
    setLoading(true)
    if (props.periodType === 'today') {
      const array = []

      try {
        const querySnapshot = await db
          .collection('project')
          .doc(props.dashboardId)
          .collection('widget')
          .get()

        // console.log(querySnapshot.docs.length)
        for (const doc of querySnapshot.docs) {
          // const setting = doc.data().setting
          // const input_parameter = setting.input.parameter_id
          // const output_parameter = setting.output.parameter_id

          // const setting = doc.data().setting
          const midnight = new Date()
          midnight.setHours(0, 0, 0, 0)
          const start_timestamp = Math.floor(midnight.getTime() / 1000)
          const next_timestamp = start_timestamp + (86400 - 1)

          // const list_log_input = await fetchLogs(input_parameter, start_timestamp, next_timestamp)
          // const list_log_output = await fetchLogs(output_parameter, start_timestamp, next_timestamp)

          // let count_sec_input = 0
          // if (setting.input.calculation === 'event_counter') {
          //   list_log_input.sort((a, b) => a.t - b.t)
          //   const min_condition = setting.input.min_condition
          //   const max_condition = setting.input.max_condition

          //   if (min_condition !== 'inf' && max_condition === 'inf') {
          //     count_sec_input = await getSecondOfDataLog(
          //       list_log_input,
          //       min_condition,
          //       max_condition
          //     )
          //   }
          // }

          // let count_sec_output = 0
          // if (setting.input.calculation === 'event_counter') {
          //   list_log_output.sort((a, b) => a.t - b.t)
          //   const min_condition = setting.output.min_condition
          //   const max_condition = setting.output.max_condition

          //   if (min_condition !== 'inf' && max_condition === 'inf') {
          //     count_sec_output = await getSecondOfDataLog(
          //       list_log_output,
          //       min_condition,
          //       max_condition
          //     )
          //   }
          // }

          // const percent = ((count_sec_output / count_sec_input) * 100).toFixed(2)
          // // console.log(percent)
          // console.log(doc.data().name, ' => percent :', percent)
          // const count_hr_output = await secondsToHourswithOutHr(count_sec_output)
          // const count_hr_input = await secondsToHourswithOutHr(count_sec_input)
          const array_input = await getLogDay(
            doc.id,
            props.dashboardId,
            start_timestamp,
            next_timestamp
          )

          const count_sec_input = array_input.count_sec_input
          const count_sec_output = array_input.count_sec_output
          const percent = ((count_sec_output / count_sec_input) * 100).toFixed(2)

          const count_hr_output = await secondsToHourswithOutHr(count_sec_output)
          const count_hr_input = await secondsToHourswithOutHr(count_sec_input)

          array.push({
            id: doc.id,
            name: doc.data().name,
            efficiency: percent,
            runtime: count_hr_input,
            fullload: count_hr_output,
          })
        }

        array.sort((a, b) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()

          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })

        const headers = [
          {label: 'Name', key: 'name'},
          {label: 'Full load (hr)', key: 'fullload'},
          {label: 'Runtime (hr)', key: 'runtime'},
          {label: 'Efficiency (%)', key: 'efficiency'},
        ]

        const today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
        const yyyy = today.getFullYear()

        const date_now = `${dd}/${mm}/${yyyy}`

        const exportToCSV = () => {
          const csvData = [
            headers.map((header) => header.label),
            ...array.map((item) => headers.map((header) => item[header.key])),
          ]
          const csvContent =
            'data:text/csv;charset=utf-8,Date,' +
            date_now +
            '\n' +
            csvData.map((row) => row.join(',')).join('\n')

          const encodedUri = encodeURI(csvContent)
          const link = document.createElement('a')
          link.setAttribute('href', encodedUri)
          link.setAttribute('download', `${props.dashboardName}_today_${date_now}.csv`)
          document.body.appendChild(link)
          link.click()
        }

        exportToCSV()
        // console.log(props)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error)
      }
    } else {
      const array = []

      try {
        const querySnapshot = await db
          .collection('project')
          .doc(props.dashboardId)
          .collection('widget')
          .get()

        for (const doc of querySnapshot.docs) {
          const setting = doc.data().setting
          // const input_parameter = setting.input.parameter_id
          // const output_parameter = setting.output.parameter_id
          const widgetId = doc.id
          const midnight = new Date(props.customStartDate)
          const midnight_stop = new Date(props.customStopDate)

          midnight.setHours(0, 0, 0, 0)
          midnight_stop.setHours(23, 59, 59, 999)
          const start_timestamp = Math.floor(midnight.getTime() / 1000)
          const next_timestamp = Math.floor(midnight_stop.getTime() / 1000)

          let count_sec_input = 0
          let count_sec_output = 0

          async function fetchLogData() {
            try {
              const logDayCollection = await db
                .collection('project')
                .doc(props.dashboardId)
                .collection('widget')
                .doc(widgetId)
                .collection('log_day2')
                .where('start_timestamp', '>=', start_timestamp)
                .where('start_timestamp', '<=', next_timestamp)
                .orderBy('start_timestamp', 'desc')
                .get()

              logDayCollection.forEach(async (doc) => {
                count_sec_input += doc.data().count_sec_input
                count_sec_output += doc.data().count_sec_output
              })
            } catch (error) {
              console.error('Error getting documents: ', error)
            }
          }

          await fetchLogData()

          // console.log(count_sec_input)
          // console.log(count_sec_output)

          const percent = ((count_sec_output / count_sec_input) * 100).toFixed(2)
          // console.log(percent)

          const count_hr_output = await secondsToHourswithOutHr(count_sec_output)
          const count_hr_input = await secondsToHourswithOutHr(count_sec_input)

          array.push({
            id: doc.id,
            name: doc.data().name,
            efficiency: percent,
            runtime: count_hr_input,
            fullload: count_hr_output,
          })

          // const array_input = await getLogDay(
          //   doc.id,
          //   props.dashboardId,
          //   start_timestamp,
          //   next_timestamp
          // )

          // const list_log = await fetchLogsCustom(props, start_timestamp, next_timestamp, widgetId)

          // const percent = ((list_log.count_sec_output / list_log.count_sec_input) * 100).toFixed(2)
          // console.log(percent)
          // console.log(doc.data().name, ' => percent :', percent)
          // const count_hr_output = await secondsToHourswithOutHr(list_log.count_sec_output)
          // const count_hr_input = await secondsToHourswithOutHr(list_log.count_sec_input)

          // array.push({
          //   id: doc.id,
          //   name: doc.data().name,
          //   efficiency: percent,
          //   runtime: count_hr_output,
          //   fullload: count_hr_input,
          // })
        }

        array.sort((a, b) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()

          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })

        const headers = [
          {label: 'Name', key: 'name'},
          {label: 'Full load (hr)', key: 'fullload'},
          {label: 'Runtime (hr)', key: 'runtime'},
          {label: 'Efficiency (%)', key: 'efficiency'},
        ]

        const date_now = await convertDate(props)

        const exportToCSV = () => {
          const csvData = [
            headers.map((header) => header.label),
            ...array.map((item) => headers.map((header) => item[header.key])),
          ]
          const csvContent =
            'data:text/csv;charset=utf-8,Date,' +
            date_now +
            '\n' +
            csvData.map((row) => row.join(',')).join('\n')
          const encodedUri = encodeURI(csvContent)
          const link = document.createElement('a')
          link.setAttribute('href', encodedUri)
          link.setAttribute('download', `${props.dashboardName}_custom_${date_now}.csv`)
          document.body.appendChild(link)
          link.click()
        }

        exportToCSV()
        // console.log(props)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error)
      }
    }
  }

  const handleExportImageJPG = () => {
    setLoading(true)
    setIsOpen(!isOpen)
    setTimeout(() => {
      props.getImage()
      setLoading(false)
    }, 1000) // Delay execution for 1 second (adjust the time as needed)
  }

  return (
    <>
      <div className='dropdown'>
        <button
          className='btn btn-icon btn-primary'
          type='button'
          id='dropdownMenuButton'
          data-bs-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded={isOpen}
          data-bs-placement='bottom-end'
          // onClick={toggleDropdown}
          style={{height: '43.6px'}}
        >
          {loading ? (
            <PulseLoader color='white' size={5} />
          ) : (
            <i class='bi bi-three-dots-vertical'></i>
          )}
        </button>
        <div
          className={`dropdown-menu ${isOpen ? 'show' : ''}`}
          aria-labelledby='dropdownMenuButton'
          style={{cursor: 'pointer'}}
        >
          <a className='dropdown-item' onClick={handleExportImageJPG}>
            <i className='bi bi-file-earmark-image me-3'></i>
            Export Image
          </a>
          <a className='dropdown-item' onClick={() => handleExportCSV(props)}>
            <i className='bi bi-filetype-csv me-3'></i>
            Export CSV
          </a>
          <hr></hr>
          <a className='dropdown-item' onClick={() => handleEditWidget(props)}>
            <i className='bi bi-pencil-square me-3'></i>
            Edit
          </a>
          <a className='dropdown-item' onClick={() => handleDeleteDashboard(props)}>
            <i className='bi bi-trash me-3'></i>
            Delete
          </a>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit dashboard name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <Form>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Dashboard name</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={dashboardName}
                onChange={handleDashboardNameChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => submitEditDashboard(props)}>
            Submit
          </Button>

          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

async function getSecondOfDataLog(list_log_input, min_condition, max_condition) {
  let count_sec_input = 0
  for (let index = 0; index < list_log_input.length; index++) {
    const item = list_log_input[index]
    if (item.v >= min_condition && list_log_input[index + 1] !== undefined) {
      const item_next = list_log_input[index + 1]
      count_sec_input += item_next.t - item.t
    }
  }
  return count_sec_input
}

async function secondsToHours(seconds) {
  const hours = seconds / 3600 // 1 hour = 3600 seconds
  return hours.toFixed(1) + ' hr'
}

async function secondsToHourswithOutHr(seconds) {
  const hours = seconds / 3600 // 1 hour = 3600 seconds
  return hours.toFixed(1)
}

async function convertDate(props) {
  const start = new Date(props.customStartDate) // Convert to milliseconds
  const stop = new Date(props.customStopDate) // Convert to milliseconds

  const startDay = String(start.getDate()).padStart(2, '0')
  const startMonth = String(start.getMonth() + 1).padStart(2, '0') // Month is zero-based, so we add 1.
  const startYear = start.getFullYear()

  const stopDay = String(stop.getDate()).padStart(2, '0')
  const stopMonth = String(stop.getMonth() + 1).padStart(2, '0') // Month is zero-based, so we add 1.
  const stopYear = stop.getFullYear()

  return `${startDay}/${startMonth}/${startYear} - ${stopDay}/${stopMonth}/${stopYear}`
}
