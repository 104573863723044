import {useEffect, useState} from 'react'
import {Card6} from '../../../_metronic/partials/content/cards/Card6'
// import {IconUserModel} from '../profile/ProfileModels'
import {Card, Row, Col, DropdownButton, Dropdown, Container, Form, Table} from 'react-bootstrap'
import firebase from '../../firebase/FirebaseConfig'
import {MdViewModule, MdViewList} from 'react-icons/md'
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import React from 'react'
import DataTable from 'react-data-table-component'
// import {DeviceDetailModal} from './DeviceDetailModal'
import {useUser} from '../../modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import Swal from 'sweetalert2'

export function Profile() {
  const {user} = useUser()
  const db = firebase.firestore()

  const [selectedImage, setSelectedImage] = useState() // Default image

  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [telephone, setTelephone] = useState()
  const [jobPosition, setJobPosition] = useState()
  const [company, setCompany] = useState()
  const [address, setAddress] = useState()
  const [refreshDashboard, setRefreshDashboard] = useState(0)
  // console.log(user)

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  useEffect(() => {
    db.collection('user')
      .where('user_id', '==', decryptedUser_id)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0]; // Assuming there's only one matching document
          const userData = doc.data();
          
          setSelectedImage(userData.image_profile || '-');
          setFirstname(userData.firstname || '-');
          setLastname(userData.lastname || '-');
          setTelephone(userData.telephone || '-');
          setJobPosition(userData.jobPosition || '-');
          setCompany(userData.company || '-');
          setAddress(userData.address || '-');
        }
      })
      .catch((error) => {
        console.error('Error fetching user data: ', error);
      });
  }, [refreshDashboard])

  // Define event handlers to update the state variables when inputs change.
  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value)
  }

  const handleLastnameChange = (event) => {
    setLastname(event.target.value)
  }

  const handleTelephoneChange = (event) => {
    setTelephone(event.target.value)
  }

  const handleJobPositionChange = (event) => {
    setJobPosition(event.target.value)
  }

  const handleCompanyChange = (event) => {
    setCompany(event.target.value)
  }

  const handleAddressChange = (event) => {
    setAddress(event.target.value)
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setSelectedImage(e.target.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const profile_submit = () => {
    const updatedData = {
      firstname: firstname,
      lastname: lastname,
      telephone: telephone,
      jobPosition: jobPosition,
      company: company,
      address: address,
      image_profile: selectedImage,
      // Add other fields you want to update
    }

    db.collection('user')
      .where('user_id', '==', decryptedUser_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // Update the document with the new data
          doc.ref
            .update(updatedData)
            .then(() => {
              // console.log('Document successfully deleted!')
              Swal.fire('Saved!', '', 'success').then((result) => {
                if (result.isConfirmed) {
                  setRefreshDashboard(refreshDashboard + 1)
                }
              })
            })
            .catch((error) => {
              console.error('Error removing document: ', error)
              Swal.fire('Error!', '', 'error')
            })
        })
      })
      .catch((error) => {
        console.error('Error getting documents: ', error)
      })

    // db.collection('user')
    //   .where('user_id', '==', decryptedUser_id)
    //   .update({
    //     firstname: firstname,
    //     lastname: lastname,
    //     telephone: telephone,
    //     jobPosition: jobPosition,
    //     company: company,
    //     address: address,
    //     image_profile: selectedImage,
    //   })
    //   .then((docRef) => {
    //     console.log('Document written with ID: ', docRef.id)
    //     Swal.fire({
    //       title: 'Create widget complete',
    //       icon: 'success',
    //       confirmButtonText: 'Close',
    //     }).then((result) => {
    //       // setShowModalOutput(false)
    //     })
    //   })
    //   .catch((error) => {
    //     console.error('Error adding document: ', error)
    //     Swal.fire({
    //       title: 'Error',
    //       icon: 'error',
    //       confirmButtonText: 'Close',
    //     }).then((result) => {
    //       // setShowModalOutput(false)
    //     })
    //   })
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2' style={{color: 'white'}}>
          Profile
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1' style={{color: 'white'}}>
            Active
          </span> */}
        </h3>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-7 mx-auto'>
            <div className='card card-custom card-stretch-100 shadow mb-5'>
              <div className='card-body'>
                <div className='col-lg-8 mb-10'>
                  <div className='d-flex align-items-center'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{backgroundImage: `url('${selectedImage}')`}}
                    >
                      <div
                        className='image-input-wrapper w-150px h-150px'
                        style={{borderRadius: '20px', backgroundImage: `url('${selectedImage}')`}}
                      ></div>
                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                      >
                        <i className='ki-duotone ki-pencil fs-7'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        <input
                          type='file'
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          onChange={handleImageChange}
                          src={user?.image_profile}
                        />
                      </label>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                      >
                        <i className='ki-duotone ki-cross fs-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>
                    <p className='ms-15'>
                      <h1>
                        {user?.firstname} {user?.lastname}
                      </h1>
                      <span className='text-muted' style={{fontSize: '15px'}}>
                        {user?.email}
                      </span>
                    </p>
                  </div>
                </div>

                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group row mb-8' style={{marginLeft: '15px'}}>
                        <label className='form-label col-3 mt-2'>Firstname</label>
                        <div className='col-sm-8'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputName'
                            value={firstname}
                            onChange={handleFirstnameChange}
                          />
                        </div>
                      </div>

                      <div className='form-group row mb-8' style={{marginLeft: '15px'}}>
                        <label className='form-label col-3 mt-2'>Lastname</label>
                        <div className='col-sm-8'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputName'
                            value={lastname}
                            onChange={handleLastnameChange}
                          />
                        </div>
                      </div>

                      <div className='form-group row mb-8' style={{marginLeft: '15px'}}>
                        <label className='form-label col-3 mt-2'>Phone</label>
                        <div className='col-sm-8'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputPhone'
                            value={telephone}
                            onChange={handleTelephoneChange}
                          />
                        </div>
                      </div>

                      <div className='form-group row mb-8' style={{marginLeft: '15px'}}>
                        <label className='form-label col-3 mt-2'>Job Positions</label>
                        <div className='col-sm-8'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputJobPosition'
                            value={jobPosition}
                            onChange={handleJobPositionChange}
                          />
                        </div>
                      </div>

                      <div className='form-group row mb-8' style={{marginLeft: '15px'}}>
                        <label className='form-label col-3 mt-2'>Company</label>
                        <div className='col-sm-8'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputCompany'
                            value={company}
                            onChange={handleCompanyChange}
                          />
                        </div>
                      </div>

                      <div className='form-group row' style={{marginLeft: '15px'}}>
                        <label className='form-label col-3 mt-2'>Address</label>
                        <div className='col-sm-8'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='inputAddress'
                            value={address}
                            onChange={handleAddressChange}
                          />
                        </div>
                      </div>

                      <div className='d-flex justify-content-end'>
                        <div className='mt-20'>
                          {/* <a href='#' className='btn btn-secondary btn-sm'>
                            Cancel
                          </a> */}
                          &nbsp;&nbsp;
                          <a href='#' className='btn btn-primary btn-sm' onClick={profile_submit}>
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
