import {useEffect, useState} from 'react'
import React from 'react'
import DataTable from 'react-data-table-component'
import {useUser} from '../../modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import firebase from '../../firebase/FirebaseConfig'

export function AddParameter({selectedRows, setSelectedRows}) {
  const [parameterData, setParameterData] = useState([])
  const [searchText, setSearchText] = useState('')
  //   const [selectedRows, setSelectedRows] = useState([])

  const {user} = useUser()
  const db = firebase.firestore()
//   console.log(user)
//   console.log(user.uid_malin1)
  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )
//   console.log(decryptedUser_id)

  useEffect(() => {
    async function fetchData() {
      try {
        const obj = []
        const querySnapshot = await db
          .collection('parameter')
          .where('owner', '==', decryptedUser_id)
          .get()

        querySnapshot.forEach(async (doc) => {
          let timestamp_format = ''
          if (doc.data().data !== undefined) {
            timestamp_format = await getTimestampFormat(doc.data().data.timestamp * 1000)
          }
          obj.push({
            id: doc.id,
            name: doc.data().name,
            parameter_id: doc.data().parameter_id,
            owner_malin1: doc.data().owner_malin1,
            timestamp: timestamp_format,
            value: doc.data().data?.value || '',
          })
        })

        console.log(obj)

        setParameterData(obj)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [user])

  const columns = [
    {
      name: 'Parameter name',
      selector: (row) => row.name,
    },
    {
      name: 'Parameter ID',
      selector: (row) => row.parameter_id,
    },
  ]

  const filteredDataForTable = parameterData.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.id && item.id.toString().toLowerCase().includes(searchText.toLowerCase())) ||
      (item.parameter_id && item.parameter_id.toLowerCase().includes(searchText.toLowerCase()))
  )

  const handleRowSelected = (rows) => {
    setSelectedRows(rows.selectedRows)
  }

  return (
    <>
      <div className='row mb-10 mt-3'>
        <div className='col-1 mt-2'>
        <label className='form-label'>Search</label>
        </div>

        <div className='col-3'>
        <input
          type='text'
          className='form-control form-control-sm'
          value={searchText} // Controlled input value
          onChange={(e) => setSearchText(e.target.value)}
        />
        </div>
        
      </div>

      <p>Selected Rows: {selectedRows.length}</p>
      <DataTable
        columns={columns}
        data={filteredDataForTable}
        selectableRows
        selectableRowsSingle
        pagination
        onSelectedRowsChange={handleRowSelected}
      />
    </>
  )
}

async function getTimestampFormat(timestamp) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
  
    const date = new Date(timestamp)
  
    // Get the numeric month (0-based) and map it to the month name
    const month = monthNames[date.getMonth()]
  
    // Get the day, year, and time as before
    const day = date.getDate()
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
  
    // Format the date and time as a string
    const formattedDate = `${month} ${day}, ${year}`
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  
    // Combine date and time into a single string
    const formattedDateTime = `${formattedDate} ${formattedTime}`
  
    return formattedDateTime
  }
