/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `none`
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/patterns/header-bg.jpg')})`
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
  {/* begin::Body */}
  <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
    {/* begin::Form */}
    <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
      {/* begin::Wrapper */}
      <div className='w-100 w-lg-500px p-10'> {/* Added w-100 for responsiveness */}
        <Outlet />
      </div>
      {/* end::Wrapper */}
    </div>
    {/* end::Form */}
    
    {/* begin::Footer */}
    <div className='d-flex flex-center flex-wrap px-5'>
      {/* begin::Links */}
      <div className='d-flex fw-semibold text-primary fs-base'>
        <a href='#' className='px-5' target='_blank'>
          Terms
        </a>
        
        <a href='#' className='px-5' target='_blank'>
          Plans
        </a>
        
        <a href='#' className='px-5' target='_blank'>
          Contact Us
        </a>
      </div>
      {/* end::Links */}
    </div>
    {/* end::Footer */}
  </div>
  {/* end::Body */}
  
  {/* begin::Aside */}
  <div
    className='d-flex flex-lg-row-fluid w-100 w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2' // Added w-100 for responsiveness
    style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/login1.png')})`}}
  >
    {/* begin::Content */}
    <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
      {/* begin::Logo */}
      <Link to='/'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo1.png')} className='w-xl-160px' style={{ width: '190px', position: 'relative', top: '30px' }} />
      </Link>
      {/* end::Logo */}
      
      {/* begin::Image */}
      <img
        className='mx-auto w-100 w-md-300px w-xl-550px mt-10' // Added w-100 for responsiveness
        style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto', position: 'relative', top: '65px' }}
        src={toAbsoluteUrl('/media/misc/screen1.png')}
        alt=''
      />
      {/* end::Image */}
      
      {/* begin::Text */}
      <div className='text-white fs-base text-center'>
        {/* In this kind of post,{' '}
        <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
          the blogger
        </a>
        introduces a person they’ve interviewed <br /> and provides some background information
        about
        <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
          the interviewee
        </a>
        and their <br /> work following this is a transcript of the interview. */}
        
        Application platform for importing data to processing and find the result of the <br/>
        efficiency of the machine or product by displayed as an overview dashboard 
      </div>
      {/* end::Text */}
    </div>
    {/* end::Content */}
  </div>
  {/* end::Aside */}
</div>

  )
}

export {AuthLayout}
