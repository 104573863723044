import {useEffect, useState} from 'react'
import React from 'react'
import DataTable from 'react-data-table-component'
import {useUser} from '../../modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {createTheme} from 'react-data-table-component'

export function ImportParameter({
  selectedRows,
  setSelectedRows,
  countTriggerImport,
  parameterIdImpoerted,
}) {
  const [parameterData, setParameterData] = useState([])
  const [searchText, setSearchText] = useState('')
  //   const [selectedRows, setSelectedRows] = useState([])
  const {user} = useUser()
  const {mode} = useThemeMode()

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          'https://asia-southeast1-dygistechplatform.cloudfunctions.net/test_api/parameter/owner/' +
            user.uid_malin1
        )
        const jsonData = await response.json()

        let obj = []
        // console.log(parameterIdImpoerted)
        const formattedData = await Promise.all(
          jsonData.map(async (item) => {
            if (!parameterIdImpoerted.includes(item.id)) {
              obj.push({
                id: item.id,
                name: item.name,
                parameter_id: item.id,
                ownwer: user.uid_malin1,
              })
            }
          })
        )
        setParameterData(obj)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [user, countTriggerImport])

  const columns = [
    {
      name: 'Parameter name',
      selector: (row) => row.name,
    },
    {
      name: 'Parameter ID',
      selector: (row) => row.parameter_id,
    },
  ]

  const filteredDataForTable = parameterData.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.id && item.id.toString().toLowerCase().includes(searchText.toLowerCase())) ||
      (item.parameter_id && item.parameter_id.toLowerCase().includes(searchText.toLowerCase()))
  )

  const handleRowSelected = (rows) => {
    setSelectedRows(rows.selectedRows)
  }

  createTheme('light', {
    background: {
      default: 'transparent',
    },
  })

  createTheme('dark', {
    background: {
      default: 'transparent',
    },
  })
  const selectedTheme = mode === 'dark' ? 'dark' : 'light'

  return (
    <>
      <div className='mb-10'>
        <div className='row'>
          <div className='col-1 mt-3'>
            <label className='form-label'>Search</label>
          </div>

          <div className='col-4'>
            <input
              type='text'
              className='form-control'
              value={searchText} // Controlled input value
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>

      <p>Selected Rows: {selectedRows.length}</p>
      <DataTable
        columns={columns}
        data={filteredDataForTable}
        selectableRows
        selectableRowsSingle
        theme={selectedTheme}
        pagination
        onSelectedRowsChange={handleRowSelected}
      />
    </>
  )
}
