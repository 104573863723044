import React, {useState, useEffect} from 'react'
import {Dropdown, DropdownButton, ButtonGroup, Modal, Button, Form} from 'react-bootstrap'
import Firebase from '../../firebase/FirebaseConfig'
import {useUser} from '../../modules/newauth/UserContext'

import CryptoJS from 'crypto-js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function ProjectButton(props) {
  const [showModal, setShowModal] = useState(false)
  const [dashboardNameNew, setDashboardNameNew] = useState('')
  const [dashboardType, setDashboardType] = useState('gauge')
  const [dashboardList, setDashboardList] = useState([])

  const {user} = useUser()
  const MySwal = withReactContent(Swal)

  const handleDropdownSelect = (selectedItem) => {
    const [selectedId, selectedName] = selectedItem.split(',')
    console.log(selectedId)
    if (selectedId === 'add_new_dashboard') {
      setShowModal(true)
    } else {
      props.setDashboardId(selectedId)
      props.setDashboardName(selectedName)
      localStorage.setItem("dashboardId", JSON.stringify(selectedId));
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setDashboardNameNew('')
  }

  const submitNewDashboard = () => {
    MySwal.fire({
      title: <p>Please wait</p>,
      didOpen: () => {
        MySwal.showLoading()
      },
    })
    
    const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
      CryptoJS.enc.Utf8
    )

    Firebase.firestore()
      .collection('project')
      .add({
        name: dashboardNameNew,
        owner: decryptedUser_id,
      })
      .then((docRef) => {
        MySwal.fire({
          icon: 'success',
          title: 'Create new dashboard complete',
        }).then((result) => {
          setShowModal(false)
          setDashboardNameNew('')
        })

        // console.log('Document written with ID: ', docRef.id)
      })
      .catch((error) => {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        }).then((result) => {
          setShowModal(false)
          setDashboardNameNew('')
        })

        // console.error('Error adding document: ', error)
      })
  }

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )
  useEffect(() => {
    const unsubscribe = Firebase.firestore()
      .collection('project')
      .where('owner', '==', decryptedUser_id)
      .orderBy('name')
      .onSnapshot(
        (querySnapshot) => {
          const newList = []
          querySnapshot.forEach((doc) => {
            newList.push({name: doc.data().name, id: doc.id})
          })
          setDashboardList(newList)
        },
        (error) => {
          console.error('Error getting documents: ', error)
        }
      )

    return () => unsubscribe()
  }, [decryptedUser_id])
  // console.log(dashboardList)
  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        key={'primary'}
        id={`dropdown-variants-Primary`}
        variant={'primary'}
        title={props.dashboardName ? props.dashboardName : 'Select Dashboard'}
        onSelect={handleDropdownSelect}
      >
        {dashboardList.map(
          (item) => (
            // item == props.dashboardId ? (
            <Dropdown.Item key={item.name} eventKey={`${item.id},${item.name}`}>
              {item.name}
            </Dropdown.Item>
          )
          // ) : (
          // <Dropdown.Item key={item} eventKey={item} active>
          //   {item}
          // </Dropdown.Item>
          // )
        )}

        <Dropdown.Divider />
        <Dropdown.Item eventKey='add_new_dashboard'>+ New Dashboard</Dropdown.Item>
      </DropdownButton>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create new dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <Form>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Dashboard name</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={dashboardNameNew}
                onChange={(e) => setDashboardNameNew(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Dashboard type</Form.Label>
              <Form.Select aria-label='Default select example'>
                <option value='gauge'>Gauge</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary ' onClick={submitNewDashboard}>
            Submit
          </Button>

          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ProjectButton
