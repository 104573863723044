import React, { createContext, useContext, useState, useEffect } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import CryptoJS from 'crypto-js' // Import crypto-js for encryption

const firestore = firebase.firestore()
const UserContext = createContext()

export function useUser() {
  return useContext(UserContext)
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null)
  const auth = getAuth()
  const [loading, setLoading] = useState(true) // Prevent Log in page

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        firestore
          .collection('user')
          .where('user_id', '==', userAuth.uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data()
              const encryptedUser_id = data.user_id
              const userInfo = {
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                image_profile: data.image_profile,
                type: data.type,
                telephone: data?.telephone,
                jobPosition: data?.jobPosition,
                company: data?.company,
                address: data?.address,
                uid_malin1: data?.uid,
                user_id: CryptoJS.AES.encrypt(encryptedUser_id, 'encryptionSecretKey').toString(),
              }
              setUser(userInfo)
              setLoading(false) // Set loading to false when user data is fetched
            })
          })
      } else {
        // User is signed out
        setUser(null)
        setLoading(false) // Set loading to false
      }
    })
    return () => unsubscribe() // Unsubscribe when the component unmounts
  }, [auth])

  const logout = async () => {
    try {
      localStorage.removeItem('dashboardId')
      localStorage.removeItem('periodType')
      await signOut(auth)
      setUser(null) // Clear the user data from context
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  if (loading) {
    return null
  }

  return (
    <UserContext.Provider value={{ user, setUser, logout, loading }}>{children}</UserContext.Provider>
  )
}
