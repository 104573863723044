import {useEffect, useState} from 'react'
import React from 'react'
import DataTable from 'react-data-table-component'
import {useUser} from '../../modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import firebase from '../../firebase/FirebaseConfig'

import {Line} from 'react-chartjs-2'
import {Chart} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
Chart.register(annotationPlugin)

const db = firebase.firestore()

export function ChartParameterData(props) {
  const {user} = useUser()

  const [dataValue, setDataValue] = useState([])
  const [dataTime, setDataTime] = useState([])

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  useEffect(() => {
    async function fetchData() {
      try {
        console.log(props.parameterId)
        const currentTimestamp = Math.floor(Date.now() / 1000)
        const pastTimestamp = currentTimestamp - 86400 / 1
        let array = []
        let array_value = []
        let array_time = []
        let parameter_id = ''
        await db
          .collection('parameter')
          .where('parameter_id', '==', props.parameterId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              console.log(doc.data())
              parameter_id = doc.id
            })
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })

        console.log(parameter_id)

        let count = 0
        let avg = 0
        await db
          .collection('parameter')
          .doc(parameter_id)
          .collection('log_hour')
          .where('start_time', '<=', currentTimestamp)
          .where('start_time', '>=', pastTimestamp)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              doc.data().log.forEach(async function (item) {
                // console.log(item)

                if (count < 45) {
                  avg += item.v
                  count++
                } else {
                  avg = avg / 45
                  array_time.push(item.t)
                  array_value.push(avg)
                  count = 0
                  avg = 0
                }
              })
            })
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })

        console.log(array_value)

        const convertedTimes = array_time.map(timestampToHHMM)
        console.log(convertedTimes)

        setDataValue(array_value)
        setDataTime(convertedTimes)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [user, props.parameterId])

  const data = {
    labels: dataTime,
    datasets: [
      {
        label: 'Data',
        data: dataValue,
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  }
  // const annotation1 = {
  //   type: 'box',
  //   backgroundColor: 'rgba(0, 150, 0, 0.2)',
  //   borderColor: 'rgba(0, 150, 0)',
  //   borderRadius: 4,
  //   borderWidth: 1,
  //   label: {
  //     display: true,
  //     content: 'Fade'
  //   },
  //   xMax: 30,
  //   xMin: 0,
  //   yMax: props.minConditionOutput,
  //   yMin: props.maxConditionOutput
  // };
  const options = {
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: props.minConditionOutput === "inf" ? 0 : props.minConditionOutput,
            yMax: props.minConditionOutput === "inf" ? 0 : props.minConditionOutput,
            // borderColor: 'rgb(255, 99, 132)',
            borderColor: '#86c5da',

            borderWidth: 3,
            label: {
              content: props.minConditionOutput === "inf" ? 'Max' : '', // Custom label when it's "inf"
              enabled: true,
              position: 'top',
            },
          },
          line2: {
            type: 'line',
            yMin: props.maxConditionOutput === "inf" ? 0 : props.maxConditionOutput,
            yMax: props.maxConditionOutput === "inf" ? 0 : props.maxConditionOutput,
            // borderColor: 'rgba(153, 102, 255)',
            borderColor: '#8b0000',
            borderWidth: 3,
            label: {
              content: props.maxConditionOutput === "inf" ? 'Max' : '', // Custom label when it's "inf"
              enabled: true,
              position: 'top',
            },
          },
        },

        // common: {
        //   drawTime: 'beforeDraw',
        // },
        // annotations: {
        //   annotation1,
        // },
      },
    },
  }

  return (
    <>
      {/* Ensure that the div is rendered */}
      <center>
        {' '}
        <div className='chart-container' style={{width: '100%', height: '150px'}}>
          {/* Ensure that the Line chart is rendered */}
          <Line data={data} options={options} />
        </div>
      </center>
    </>
  )
}

async function formatTime(timestamp) {
  timestamp = timestamp * 1000
  const date = new Date(timestamp)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based, so we add 1
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const formattedTime = `${day}:${month} ${hours}:${minutes}`
  return formattedTime
}

function timestampToHHMM(timestamp) {
  const date = new Date((timestamp + 25200) * 1000) // Convert seconds to milliseconds
  const hours = String(date.getUTCHours()).padStart(2, '0') // Get hours and pad with leading zero if necessary
  const minutes = String(date.getUTCMinutes()).padStart(2, '0') // Get minutes and pad with leading zero if necessary
  return `${hours}:${minutes}`
}
