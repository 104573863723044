import {useEffect, useState} from 'react'

import {useUser} from '../../modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import firebase from '../../firebase/FirebaseConfig'
import {ImportParameter} from './ImportParameter'
import DataTable from 'react-data-table-component'
import Swal from 'sweetalert2'
import {ParameterDetailModal} from './ParameterDetailModal'
import {Table} from 'react-bootstrap'
import {createTheme} from 'react-data-table-component'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
export function Setting() {
  const [showModal, setShowModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const [parameterData, setParameterData] = useState([])
  const [searchText, setSearchText] = useState('')

  const [showParameterDetailModal, setShowParameterDetailModal] = useState(false)
  const [parameterDetailId, setParameterDetailId] = useState('')

  const [countTriggerImport, setCountTriggerImport] = useState(0)
  const [parameterIdImpoerted, setParameterIdImpoerted] = useState([])

  const {user} = useUser()
  const db = firebase.firestore()
  const {mode} = useThemeMode()

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  useEffect(() => {
    const obj = []
    const collectionRef = db.collection('parameter')
    const unsubscribe = collectionRef
      .where('owner', '==', decryptedUser_id)
      .onSnapshot(async (querySnapshot) => {
        const updatedParameterData = []
        const importedParameterId = []

        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            let timestamp_format = ''
            if (doc.data().data?.timestamp !== undefined) {
              timestamp_format = await getTimestampFormat(doc.data().data.timestamp * 1000)
            }

            let value = ''
            if (doc.data().data?.value !== undefined) {
              value = parseFloat(doc.data().data.value.toFixed(2))
            }

            let import_timestamp_format = ''
            if (doc.data().import_timestamp !== undefined) {
              import_timestamp_format = await getTimestampFormat(doc.data().import_timestamp * 1000)
            }
            updatedParameterData.push({
              id: doc.id,
              name: doc.data().name,
              parameter_id: doc.data().parameter_id,
              owner_malin1: doc.data().owner_malin1,
              timestamp: timestamp_format,
              value: value,
              import_timestamp: import_timestamp_format,
              unit: doc.data().unit,
            })

            importedParameterId.push(doc.data().parameter_id)
          })
        )

        setParameterData(updatedParameterData)
        setParameterIdImpoerted(importedParameterId)
      })

    return () => {
      unsubscribe()
    }
  }, [user])

  const toggleButton = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setCountTriggerImport(countTriggerImport + 1)
  }

  const submitModal = async () => {
    const parameter_id = selectedRows[0].id
    var url =
      'https://asia-southeast1-dygistechplatform.cloudfunctions.net/test_api/parameter/' +
      parameter_id

    const response = await fetch(url)
    const jsonData = await response.json()

    const importTimestamp = Math.floor(new Date().getTime() / 1000)
    const owner_malin1 = jsonData.owner
    const unit = jsonData.unit
    const unit_id = jsonData.unit_id

    await db
      .collection('parameter')
      .add({
        name: selectedRows[0].name,
        owner: decryptedUser_id,
        owner_malin1: owner_malin1,
        parameter_id: selectedRows[0].id,
        import_timestamp: importTimestamp,
        unit: unit,
        unit_id: unit_id,
      })
      .then((docRef) => {
        // console.log('Document written with ID: ', docRef.id)
        Swal.fire({
          title: 'Import parameter complete',
          icon: 'success',
          confirmButtonText: 'Close',
        }).then((result) => {
          setShowModal(false)
        })
      })
      .catch((error) => {
        console.error('Error adding document: ', error)
        Swal.fire({
          title: 'Error',
          icon: 'error',
          confirmButtonText: 'Close',
        }).then((result) => {
          setShowModal(false)
        })
      })
  }

  const columns = [
    {
      name: 'Parameter Name',
      selector: (row) => row.name,
      sortable: true,
      width: '15%',
    },
    {
      name: 'Parameter ID',
      selector: (row) => row.parameter_id,
      sortable: true,
      width: '10%',
    },
    {
      name: 'Last Update',
      selector: (row) => row.timestamp,
      sortable: true,
      width: '20%',
    },
    {
      name: 'Value',
      sortable: true,
      selector: (row) => row.value,
      width: '10%',
    },
    {
      name: 'Unit',
      sortable: true,
      selector: (row) => row.unit,
      width: '10%',
    },
    {
      name: 'Import Time',
      selector: (row) => row.import_timestamp,
      sortable: true,
      width: '15%',
    },
    {
      cell: (row) => (
        <button className='btn btn-info btn-sm' onClick={() => handleDetailParameter(row)}>
          Detail
        </button>
      ),
      button: 'true',
    },
    {
      cell: (row) => (
        <button className='btn btn-danger btn-sm' onClick={() => handleRemoveParameter(row)}>
          Remove
        </button>
      ),
      button: 'true',
    },
  ]

  const filteredDataForTable = parameterData.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.id && item.id.toString().toLowerCase().includes(searchText.toLowerCase())) ||
      (item.parameter_id && item.parameter_id.toLowerCase().includes(searchText.toLowerCase()))
  )

  const handleRemoveParameter = (row) => {
    // console.log(row.id)

    Swal.fire({
      title: 'Do you want to remove parameter ' + row.name + ' ?',
      showDenyButton: true,
      confirmButtonText: 'Remove',
      denyButtonText: `Cancel`,
      // confirmButtonColor: '#ff5733',
      // denyButtonColor: '#34a4eb',
      confirmButtonColor: '#f1416c',
      denyButtonColor: '#0077ba',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // console.log(row.id)

        await db
          .collection('parameter')
          .doc(row.id)
          .delete()
          .then(async () => {
            // console.log('Document successfully deleted!')

            await db
              .collection('parameter')
              .doc(row.id)
              .collection('log_hour')
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                  await db
                    .collection('parameter')
                    .doc(row.id)
                    .collection('log_hour')
                    .doc(doc.id)
                    .delete()
                    .then(async () => {
                      // console.log('Document successfully deleted!')
                      Swal.fire('Saved!', '', 'success')
                    })
                    .catch((error) => {
                      console.error('Error removing document: ', error)
                      Swal.fire('Error!', '', 'error')
                    })
                })
              })
          })
          .catch((error) => {
            console.error('Error removing document: ', error)
            Swal.fire('Error!', '', 'error')
          })
      }
    })
  }

  const handleDetailParameter = (row) => {
    setShowParameterDetailModal(true)
    setParameterDetailId(row.id)
  }

  createTheme('light', {
    background: {
      default: 'transparent',
    },
  })

  createTheme('dark', {
    background: {
      default: 'transparent',
    },
  })
  const selectedTheme = mode === 'dark' ? 'dark' : 'light'

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2' style={{color: 'white'}}>
          Setting
        </h3>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className='card card-custom card-stretch-100 shadow mb-5'>
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col-6 d-flex justify-content-start"'>
                    <label className='form-label mt-4'>Search</label>
                    &nbsp; &nbsp;
                    <div className='col-5'>
                      <input
                        type='text'
                        class='form-control'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='col-6 d-flex justify-content-end'>
                    <button onClick={toggleButton} className='btn btn-primary '>
                      <i className='fa-solid fa-plus fs-4 me-2'></i>
                      Import Parameter
                    </button>
                  </div>
                </div>

                <div className='py-5'>
                  <DataTable
                    columns={columns}
                    data={filteredDataForTable}
                    pagination
                    theme={selectedTheme}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal} animation={true} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Import parameter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImportParameter
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            countTriggerImport={countTriggerImport}
            parameterIdImpoerted={parameterIdImpoerted}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
          <Button variant='primary' onClick={submitModal}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <ParameterDetailModal
        showParameterDetailModal={showParameterDetailModal}
        setShowParameterDetailModal={setShowParameterDetailModal}
        parameterDetailId={parameterDetailId}
      ></ParameterDetailModal>
    </>
  )
}

async function getTimestampFormat(timestamp) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const date = new Date(timestamp)

  // Get the numeric month (0-based) and map it to the month name
  const month = monthNames[date.getMonth()]

  // Get the day, year, and time as before
  const day = date.getDate()
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  // Format the date and time as a string
  const formattedDate = `${month} ${day}, ${year}`
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

  // Combine date and time into a single string
  const formattedDateTime = `${formattedDate} ${formattedTime}`

  return formattedDateTime
}
