import {useEffect, useState} from 'react'
import React from 'react'
import DataTable from 'react-data-table-component'
import {useUser} from '../../modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import firebase from '../../firebase/FirebaseConfig'

import {MixedWidget16} from '../../../_metronic/partials/widgets'
// import {ParameterChartLine} from './ParameterChartLine'

export function ParameterDetailModal(props) {
  const {user} = useUser()
  const db = firebase.firestore()

  const [parameterDetail, setParameterDetail] = useState({})
  const [parameterDataChart, setParameterDataChart] = useState([])
  const [parameterRawDataChartLine, setParameterRawDataChartLine] = useState([])

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  const closeModal = () => {
    props.setShowParameterDetailModal(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (props.parameterDetailId !== '') {
        const currentTimestamp = Math.floor(Date.now() / 1000)
        const pastTimestamp = currentTimestamp - 86400 / 2
        let array = []
        let arrayData = []

        await db
          .collection('parameter')
          .doc(props.parameterDetailId)
          .collection('log_hour')
          .where('start_time', '<=', currentTimestamp)
          .where('start_time', '>=', pastTimestamp)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              array.push([await formatTime(doc.data().start_time), doc.data().n])
              // const log = doc.data().log
              // for (let index = 0; index < log.length; index++) {
              //   arrayData.push(log[index])
              // }
            })
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })

        // console.log(array)
        // console.log(arrayData)
        setParameterDataChart(array)
        // setParameterRawDataChartLine(arrayData)
      }
    }

    fetchData()
  }, [props.parameterDetailId])

  useEffect(() => {
    const fetchData = async () => {
      if (props.parameterDetailId !== '') {
        var docRef = db.collection('parameter').doc(props.parameterDetailId)
        docRef
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              setParameterDetail({
                ...doc.data(),
                timestamp_text: await getTimestampFormat(doc.data().data.timestamp * 1000),
                import_timestamp_text: await getTimestampFormat(doc.data().import_timestamp * 1000),
              })
            } else {
              // doc.data() will be undefined in this case
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      }
    }

    fetchData()
  }, [props.parameterDetailId])

  return (
    <>
      <Modal show={props.showParameterDetailModal} onHide={closeModal} animation={true} size='md'>
        <Modal.Header closeButton>
          <Modal.Title>Parameter Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Parameter Name</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{parameterDetail.name}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Parameter ID</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{parameterDetail.parameter_id}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Value</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{parameterDetail.data?.value}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Unit</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{parameterDetail.unit}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Unit Id</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{parameterDetail.unit_id}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Last Update</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{parameterDetail.timestamp_text}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Import Time</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                  {parameterDetail.import_timestamp_text}
                </span>
              </div>
            </div>

            {parameterDataChart.length > 0 ? (
              <MixedWidget16
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='primary'
                chartHeight='175px'
                chartData={parameterDataChart}
              />
            ) : (
              <p>Loading chart data...</p> // You can show a loading message or spinner here
            )}

            {/* <ParameterChartLine parameterRawDataChartLine={parameterRawDataChartLine} /> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
          {/* <Button
            variant='primary'
            //   onClick={submitModal}
          >
            Submit
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

async function getTimestampFormat(timestamp) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const date = new Date(timestamp)

  // Get the numeric month (0-based) and map it to the month name
  const month = monthNames[date.getMonth()]

  // Get the day, year, and time as before
  const day = date.getDate()
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  // Format the date and time as a string
  const formattedDate = `${month} ${day}, ${year}`
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

  // Combine date and time into a single string
  const formattedDateTime = `${formattedDate} ${formattedTime}`

  return formattedDateTime
}

async function formatTime(timestamp) {
  timestamp = timestamp * 1000
  const date = new Date(timestamp)

  const hours = date.getHours()
  const minutes = date.getMinutes()
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
  return formattedTime
}
