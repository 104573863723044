import firebase from '../../../firebase/FirebaseConfig'
const db = firebase.firestore()

export async function fetchLogs(parameter, start_timestamp, next_timestamp) {
  let list_log_input = []
  try {
    const querySnapshot = await db
      .collection('parameter')
      .where('parameter_id', '==', parameter)
      .get()

    for (const doc of querySnapshot.docs) {
      const querySnapshot2 = await db
        .collection('parameter')
        .doc(doc.id)
        .collection('log_hour')
        .where('start_time', '>=', start_timestamp)
        .where('start_time', '<=', next_timestamp)
        .get()

      querySnapshot2.forEach((doc2) => {
        for (let index = 0; index < doc2.data().log.length; index++) {
          const log_element = doc2.data().log[index]
          list_log_input.push(log_element)
        }
      })
    }
  } catch (error) {
    console.log('Error getting documents: ', error)
  }
  return list_log_input
}

export async function fetchLogsCustom(props, start_timestamp, next_timestamp, widgetId) {
  let count_sec_input = 0;
  let count_sec_output = 0;

  try {
    const querySnapshot = await db
      .collection('project')
      .doc(props.dashboardId)
      .collection('widget')
      .doc(widgetId)
      .collection('log_day')
      .where('start_timestamp', '>=', start_timestamp)
      .where('start_timestamp', '<=', next_timestamp)
      .get();

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      count_sec_input += data.count_sec_input;
      count_sec_output += data.count_sec_output;
    });
  } catch (error) {
    console.error('Error getting documents:', error);
  }

  return { count_sec_input, count_sec_output };
}






export async function getLogDay(widgetId, dashboardId, start_timestamp, next_timestamp) {
  let obj = {}
  try {
    const querySnapshot = await db
      .collection('project').doc(dashboardId).collection('widget').doc(widgetId).collection('log_day2')
      .where('start_timestamp', '==', start_timestamp)
      .where('stop_timestamp', '==', next_timestamp)
      .get()

    for (const doc of querySnapshot.docs) {
      obj = doc.data()
    }
  } catch (error) {
    console.log('Error getting documents: ', error)
  }
  return obj
}