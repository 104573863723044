import React, {useState, useEffect} from 'react'
import {Dropdown, DropdownButton, ButtonGroup, Modal, Button, Form} from 'react-bootstrap'
import firebase from '../../firebase/FirebaseConfig'
import {useUser} from '../../modules/newauth/UserContext'

import CryptoJS from 'crypto-js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function PeriodButton(props) {
  const {user} = useUser()
  const MySwal = withReactContent(Swal)

  const [startDate, setStartDate] = useState(new Date())

  const [showModal, setShowModal] = useState(false)
  const [showCustomInput, setShowCustomInput] = useState(false)

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )
  // console.log(props)

  const handleDropdownSelect = (eventKey) => {
    console.log(eventKey)
    props.setPeriodType(eventKey)
    localStorage.setItem('periodType', JSON.stringify(eventKey))

    if (eventKey == 'custom') {
      setShowModal(true)
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleCustomSubmit = () => {
    props.setTriggerEffectCustom(true)
    setShowCustomInput(true)
    closeModal()
  }

  function handlePeriodSelect(eventKey) {
    console.log('Event key:', eventKey)
    props.setPeriodType(eventKey)
    localStorage.setItem('periodType', JSON.stringify(eventKey))

    if (eventKey == 'custom') {
      setShowModal(true)
    } else {
      setShowCustomInput(false)
    }
  }
  console.log(props)

  return (
    <>
      {/* <DropdownButton
        as={ButtonGroup}
        key={'primary'}
        id={`dropdown-variants-Primary`}
        variant={'primary'}
        title={props.periodType ? 'Period : ' + props.periodType : 'Select Period'}
        onSelect={handleDropdownSelect}
      >
        <Dropdown.Item eventKey={`today`}>{'Today'}</Dropdown.Item>
        <Dropdown.Item eventKey={`custom`}>{'Custom'}</Dropdown.Item>

        <Dropdown.Divider />
        <Dropdown.Item eventKey='add_new_dashboard'>+ New Dashboard</Dropdown.Item>
      </DropdownButton> */}

      {/* {props.periodType} */}
      <ButtonGroup aria-label='Basic example'>
        <Button
          size='md'
          onClick={() => handlePeriodSelect('today')}
          variant={props.periodType === 'today' ? 'primary' : 'secondary'}
        >
          Today
        </Button>
        <Button
          size='md'
          onClick={() => handlePeriodSelect('custom')}
          variant={props.periodType === 'custom' ? 'primary' : 'secondary'}
        >
          Custom
        </Button>
        {showCustomInput === true ? (
          <input
            type='text'
            className='form-control form-control-white'
            value={
              props.customStartDate.toDateString() + ' - ' + props.customStopDate.toDateString()
            }
            style={{
              width: '320px',
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
              borderTopRightRadius: '7px',
              borderBottomRightRadius: '7px',
              borderWidth: '1px',
              textAlign: 'center',
            }}
          />
        ) : (
          ''
        )}
      </ButtonGroup>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Customize Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          {/* <div className='text-center'> */}
          <div>
            <label className='form-label col-3 mt-2' style={{marginLeft: '20px'}}>
              Start Date
            </label>
            <DatePicker
              maxDate={new Date(new Date().getTime() - 86400000)}
              selected={props.customStartDate}
              onChange={(date) => props.setCustomStartDate(date)}
              customInput={
                <div style={{position: 'relative'}}>
                  <input
                    type='text'
                    value={props.customStartDate.toDateString()}
                    readOnly
                    placeholder='Select Date'
                    style={{
                      borderRadius: '5px', // Set the borderRadius
                      paddingRight: '30px', // Create space for the icon
                      width: '230px', // Set the width of the input field
                      height: '40px',
                      border: '1px solid',
                    }}
                  />
                  <i
                    className='fa fa-calendar'
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '10px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                      fontSize: '20px',
                    }}
                    onClick={() => {
                      // Handle click event here
                    }}
                  ></i>
                </div>
              }
            />
          </div>
          &nbsp; &nbsp;
          <div>
            <label className='form-label col-3 mt-2' style={{marginLeft: '20px'}}>
              Stop Date
            </label>

            <DatePicker
              maxDate={new Date(new Date().getTime() - 86400000)}
              selected={props.customStopDate}
              onChange={(date) => props.setCustomStopDate(date)}
              customInput={
                <div style={{position: 'relative'}}>
                  <input
                    type='text'
                    value={props.customStopDate.toDateString()}
                    readOnly
                    placeholder='Select Date'
                    style={{
                      borderRadius: '5px', // Set the borderRadius
                      paddingRight: '30px', // Create space for the icon
                      width: '230px', // Set the width of the input field
                      height: '40px',
                      border: '1px solid',
                    }}
                  />
                  <i
                    className='fa fa-calendar'
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '10px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                      fontSize: '20px',
                    }}
                    onClick={() => {
                      // Handle click event here
                    }}
                  ></i>
                </div>
              }
            />
          </div>
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary ' onClick={handleCustomSubmit}>
            Submit
          </Button>

          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
