import {useEffect, useState} from 'react'
import {Card6} from '../../../_metronic/partials/content/cards/Card6'
// import {IconUserModel} from '../profile/ProfileModels'
// import {Card, Row, Col, DropdownButton, Dropdown, Container, Form, Table} from 'react-bootstrap'
import firebase from '../../firebase/FirebaseConfig'
import {MdViewModule, MdViewList} from 'react-icons/md'
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import React from 'react'
import DataTable from 'react-data-table-component'
// import {DeviceDetailModal} from './DeviceDetailModal'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {AddParameter} from './AddParameter'
import {Link, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useUser} from '../../modules/newauth/UserContext'

import {Form, Formik} from 'formik'
import * as yup from 'yup'
import CryptoJS from 'crypto-js'

import {ChartParameterData} from './ChartParameterData'

export function CreateWidget() {
  const dotStyleGreen = {
    width: '15px',
    height: '15px',
    backgroundColor: '#50cd89',
    borderRadius: '50%',
  }

  const dotStyleRed = {
    width: '15px',
    height: '15px',
    backgroundColor: '#f1416c',
    borderRadius: '50%',
  }

  const dotStyleBlue = {
    width: '15px',
    height: '15px',
    backgroundColor: '#009ef7',
    borderRadius: '50%',
  }

  function VerticalSeparator() {
    const separatorStyles = {
      borderLeft: '2px solid #ccc',
      height: '100%',
      margin: '0 10px',
    }

    return <div style={separatorStyles}></div>
  }

  const {id} = useParams()
  const db = firebase.firestore()
  const {user} = useUser()

  const [showModalOutput, setShowModalOutput] = useState(false)
  const [showModalInput, setShowModalInput] = useState(false)

  const [dataParameterOutput, setDataParameterOutput] = useState([])
  const [dataParameterInput, setDataParameterInput] = useState([])

  const [showChartOutput, setShowChartOutput] = useState(false)
  const [showChartInput, setShowChartInput] = useState(false)

  const [widgetData, setWidgetData] = useState({
    widgetName: '',
    outputName: '',
    parameterOutput: '',
    calculationOutput: 'event_counter',
    minConditionOutput: '',
    maxConditionOutput: '',
    inputName: '',
    parameterInput: '',
    calculationInput: 'event_counter',
    minConditionInput: '',
    maxConditionInput: '',
    multiply: '100',
    suffix: '%',
    statusZone: false,
    unsatisfied: '',
    satisfied: '',
    normal: '',
    idParameterOutput: '',
    idParameterInput: '',
  })

  const addParameterOutput = () => {
    setShowModalOutput(true)
  }

  const closeModalOutput = () => {
    setShowModalOutput(false)
  }

  const addParameterInput = () => {
    setShowModalInput(true)
  }

  const closeModalInput = () => {
    setShowModalInput(false)
  }

  const handleCheckboxChange = (event) => {
    setWidgetData({
      ...widgetData,
      statusZone: event.target.checked,
    })
  }

  const submitModalOutput = () => {
    console.log(dataParameterOutput)

    dataParameterOutput.forEach((data, index) => {
      console.log(data.name)

      const parameterOutput = data.name
      const idParameterOutput = data.parameter_id
      setWidgetData((prevData) => ({
        ...prevData,
        parameterOutput,
        idParameterOutput,
      }))
    })

    closeModalOutput()
  }

  const submitModalInput = () => {
    console.log(dataParameterInput)

    dataParameterInput.forEach((data, index) => {
      console.log(data.name)

      const parameterInput = data.name
      const idParameterInput = data.parameter_id
      setWidgetData((prevData) => ({
        ...prevData,
        parameterInput,
        idParameterInput,
      }))
    })

    closeModalInput()
  }

  useEffect(() => {
    // Calculate the "Normal" value when "unsatisfied," "satisfied," or "statusZone" changes
    if (widgetData.unsatisfied !== '' && widgetData.satisfied !== '' && widgetData.statusZone) {
      const normalValue = `${widgetData.unsatisfied + 1}-${widgetData.satisfied - 1}` // Calculate the "Normal" value here
      setWidgetData((prevData) => ({
        ...prevData,
        normal: normalValue,
      }))
    } else {
      setWidgetData((prevData) => ({
        ...prevData,
        normal: '',
      }))
    }
  }, [widgetData.unsatisfied, widgetData.satisfied, widgetData.statusZone])

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )
  console.log(decryptedUser_id)
  // const create_submit = async () => {
  //   Swal.fire({
  //     title: 'Loading...',
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //     showConfirmButton: false,
  //     didOpen: () => {
  //       Swal.showLoading()
  //     },
  //   })

  //   // const midnight = new Date()
  //   // midnight.setHours(0, 0, 0, 0)
  //   // // const start_timestamp = Math.floor(midnight.getTime() / 1000) - 25200 //Ubuntu
  //   // const start_timestamp = Math.floor(midnight.getTime() / 1000) // Window
  //   // const next_timestamp = start_timestamp + (86400 - 1)

  //   // console.log('start_timestamp : ' + start_timestamp)
  //   // console.log('next_timestamp : ' + next_timestamp)

  //   // // Get list_log_input Parameter
  //   // let list_log_input = []
  //   // const querySnapshot_1 = await db
  //   //   .collection('parameter')
  //   //   .where('parameter_id', '==', widgetData.idParameterInput)
  //   //   .get()
  //   // for (const doc_1 of querySnapshot_1.docs) {
  //   //   const querySnapshot_2 = await db
  //   //     .collection(`parameter/${doc_1.id}/log_hour`)
  //   //     .where('start_time', '>=', start_timestamp)
  //   //     .where('start_time', '<=', next_timestamp)
  //   //     .get()
  //   //   for (const doc_2 of querySnapshot_2.docs) {
  //   //     for (let index = 0; index < doc_2.data().log.length; index++) {
  //   //       const log_element = doc_2.data().log[index]
  //   //       list_log_input.push(log_element)
  //   //     }
  //   //   }
  //   // }

  //   // // Get list_log_output Parameter
  //   // let list_log_output = []
  //   // const querySnapshot5 = await db
  //   //   .collection('parameter')
  //   //   .where('parameter_id', '==', widgetData.idParameterOutput)
  //   //   .get()
  //   // for (const doc_3 of querySnapshot5.docs) {
  //   //   const querySnapshot_3 = await db
  //   //     .collection(`parameter/${doc_3.id}/log_hour`)
  //   //     .where('start_time', '>=', start_timestamp)
  //   //     .where('start_time', '<=', next_timestamp)
  //   //     .get()
  //   //   for (const doc_4 of querySnapshot_3.docs) {
  //   //     for (let index = 0; index < doc_4.data().log.length; index++) {
  //   //       const log_element = doc_4.data().log[index]
  //   //       list_log_output.push(log_element)
  //   //     }
  //   //   }
  //   // }

  //   // let count_sec_input = 0
  //   // if (widgetData.calculationInput == 'event_counter') {
  //   //   list_log_input.sort((a, b) => a.t - b.t)
  //   //   const min_condition = widgetData.minConditionInput
  //   //   const max_condition = widgetData.maxConditionInput
  //   //   // Condition 1 : min_condition !== 'inf' && max_condition == 'inf'
  //   //   if (min_condition !== 'inf' && max_condition == 'inf') {
  //   //     console.log(min_condition, max_condition)
  //   //     count_sec_input = await getSecondOfDataLog(list_log_input, min_condition, max_condition)
  //   //   }
  //   // }
  //   // console.log('count_sec_input :', count_sec_input)

  //   // let count_sec_output = 0
  //   // if (widgetData.calculationOutput == 'event_counter') {
  //   //   list_log_output.sort((a, b) => a.t - b.t)
  //   //   const min_condition = widgetData.minConditionOutput
  //   //   const max_condition = widgetData.maxConditionOutput
  //   //   // Condition 1 : min_condition !== 'inf' && max_condition == 'inf'
  //   //   if (min_condition !== 'inf' && max_condition == 'inf') {
  //   //     console.log(min_condition, max_condition)
  //   //     count_sec_output = await getSecondOfDataLog(list_log_output, min_condition, max_condition)
  //   //   }
  //   // }
  //   // console.log('count_sec_output :', count_sec_output)

  //   // const currentTimestamp = Math.floor(Date.now() / 1000)
  //   // console.log(currentTimestamp)

  //   // // Save Database
  //   // db.collection('project')
  //   //   .doc(`${id}`)
  //   //   .collection('widget')
  //   //   .add({
  //   //     name: widgetData.widgetName,
  //   //     order: '',
  //   //     setting: {
  //   //       input: {
  //   //         calculation: widgetData.calculationInput,
  //   //         max_condition: widgetData.maxConditionInput,
  //   //         min_condition: widgetData.minConditionInput,
  //   //         name: widgetData.inputName,
  //   //         parameter_id: widgetData.idParameterInput,
  //   //       },
  //   //       output: {
  //   //         calculation: widgetData.calculationOutput,
  //   //         max_condition: widgetData.maxConditionOutput,
  //   //         min_condition: widgetData.minConditionOutput,
  //   //         name: widgetData.outputName,
  //   //         parameter_id: widgetData.idParameterOutput,
  //   //       },
  //   //       status_zone: {
  //   //         enable: widgetData.statusZone,
  //   //         normal: widgetData.normal,
  //   //         satisfied: widgetData.satisfied,
  //   //         unsatisfied: widgetData.unsatisfied,
  //   //       },
  //   //       multiply: widgetData.multiply,
  //   //       suffix: widgetData.suffix,
  //   //     },
  //   //   })
  //   //   .then((docRef) => {
  //   //     console.log('Document successfully deleted!')

  //   //     const newDocumentId = docRef.id

  //   //     db.collection('project')
  //   //       .doc(`${id}`)
  //   //       .collection('widget')
  //   //       .doc(newDocumentId)
  //   //       .collection('log_day2')
  //   //       .add({
  //   //         start_timestamp: start_timestamp,
  //   //         stop_timestamp: next_timestamp,
  //   //         count_sec_input: count_sec_input,
  //   //         count_sec_output: count_sec_output,
  //   //         last_update: currentTimestamp,
  //   //       })
  //   //       .then((docRef) => {
  //   //         Swal.fire('Saved!', '', 'success').then((result) => {
  //   //           if (result.isConfirmed) {
  //   //             window.location.href = '../dashboard'
  //   //           }
  //   //         })
  //   //       })
  //   //       .catch((error) => {
  //   //         console.error('Error adding document: ', error)
  //   //         Swal.fire('Error!', '', 'error')
  //   //       })
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error('Error removing document: ', error)
  //   //     Swal.fire('Error!', '', 'error')
  //   //   })
  // }

  const handleShowChart = () => {
    setShowChartOutput(!showChartOutput)
  }
  const handleShowChartInput = () => {
    setShowChartInput(!showChartInput)
  }

  const initialDataForm = {
    name: '',
    output_name: '',
    output_min_condition: '',
    output_max_condition: '',
    input_name: '',
    input_min_condition: '',
    input_max_condition: '',
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    output_name: yup.string().required('Output Name is required'),
    output_min_condition: yup
      .string()
      .test('isInfOrNumber', 'Invalid min condition', (value) => {
        return value === 'inf' || !isNaN(Number(value))
      })
      .required('Min condition is required'),
    output_max_condition: yup
      .string()
      .test('isInfOrNumber', 'Invalid max condition', (value) => {
        return value === 'inf' || !isNaN(Number(value))
      })
      .required('Max condition is required'),
    input_name: yup.string().required('Input Name is required'),
    input_min_condition: yup
      .string()
      .test('isInfOrNumber', 'Invalid min condition', (value) => {
        return value === 'inf' || !isNaN(Number(value))
      })
      .required('Min condition is required'),
    input_max_condition: yup
      .string()
      .test('isInfOrNumber', 'Invalid max condition', (value) => {
        return value === 'inf' || !isNaN(Number(value))
      })
      .required('Max condition is required'),
  })

  const submitCreateUser = async (values) => {
    console.log(values)

    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    const midnight = new Date()
    midnight.setHours(0, 0, 0, 0)
    // const start_timestamp = Math.floor(midnight.getTime() / 1000) - 25200 //Ubuntu
    const start_timestamp = Math.floor(midnight.getTime() / 1000) // Window
    const next_timestamp = start_timestamp + (86400 - 1)

    console.log('start_timestamp : ' + start_timestamp)
    console.log('next_timestamp : ' + next_timestamp)

    // Get list_log_input Parameter
    let list_log_input = []
    const querySnapshot_1 = await db
      .collection('parameter')
      .where('parameter_id', '==', widgetData.idParameterInput)
      .where('owner','==',decryptedUser_id)
      .get()
    for (const doc_1 of querySnapshot_1.docs) {
      const querySnapshot_2 = await db
        .collection(`parameter/${doc_1.id}/log_hour`)
        .where('start_time', '>=', start_timestamp)
        .where('start_time', '<=', next_timestamp)
        .get()
      for (const doc_2 of querySnapshot_2.docs) {
        for (let index = 0; index < doc_2.data().log.length; index++) {
          const log_element = doc_2.data().log[index]
          list_log_input.push(log_element)
        }
      }
    }

    // Get list_log_output Parameter
    let list_log_output = []
    const querySnapshot5 = await db
      .collection('parameter')
      .where('parameter_id', '==', widgetData.idParameterOutput)
      .where('owner','==',decryptedUser_id)
      .get()
    for (const doc_3 of querySnapshot5.docs) {
      const querySnapshot_3 = await db
        .collection(`parameter/${doc_3.id}/log_hour`)
        .where('start_time', '>=', start_timestamp)
        .where('start_time', '<=', next_timestamp)
        .get()
      for (const doc_4 of querySnapshot_3.docs) {
        for (let index = 0; index < doc_4.data().log.length; index++) {
          const log_element = doc_4.data().log[index]
          list_log_output.push(log_element)
        }
      }
    }

    let count_sec_input = 0
    if (widgetData.calculationInput == 'event_counter') {
      list_log_input.sort((a, b) => a.t - b.t)
      const min_condition = values.input_min_condition
      const max_condition = values.input_max_condition
      // Condition 1 : min_condition !== 'inf' && max_condition == 'inf'
      if (min_condition !== 'inf' && max_condition == 'inf') {
        console.log("Condition 1 : min_condition !== 'inf' && max_condition == 'inf'")
        count_sec_input = await getSecondOfDataLog(list_log_input, min_condition, max_condition)
      } else if (min_condition !== 'inf' && max_condition !== 'inf') {
        console.log("Condition 2 : min_condition !== 'inf' && max_condition !== 'inf'")
        count_sec_input = await getSecondOfDataLogCondition2(
          list_log_input,
          min_condition,
          max_condition
        )
      } else {
        console.log("Condition 3 : min_condition == 'inf' && max_condition !== 'inf'")
        count_sec_input = await getSecondOfDataLogCondition3(
          list_log_input,
          min_condition,
          max_condition
        )
      }
    }
    console.log('count_sec_input : ', count_sec_input)

    let count_sec_output = 0
    if (widgetData.calculationOutput == 'event_counter') {
      list_log_output.sort((a, b) => a.t - b.t)
      const min_condition = values.output_min_condition
      const max_condition = values.output_max_condition
      // Condition 1 : min_condition !== 'inf' && max_condition == 'inf'
      if (min_condition !== 'inf' && max_condition == 'inf') {
        console.log("Condition 1 : min_condition !== 'inf' && max_condition == 'inf'")
        count_sec_output = await getSecondOfDataLog(list_log_output, min_condition, max_condition)
      } else if (min_condition !== 'inf' && max_condition !== 'inf') {
        console.log("Condition 2 : min_condition !== 'inf' && max_condition !== 'inf'")
        count_sec_output = await getSecondOfDataLogCondition2(
          list_log_input,
          min_condition,
          max_condition
        )
      } else {
        console.log("Condition 3 : min_condition == 'inf' && max_condition !== 'inf'")
        count_sec_output = await getSecondOfDataLogCondition3(
          list_log_input,
          min_condition,
          max_condition
        )
      }
    }
    console.log('count_sec_output : ', count_sec_output)

    const currentTimestamp = Math.floor(Date.now() / 1000)
    console.log(currentTimestamp)

    // Save Database
    await db.collection('project')
      .doc(`${id}`)
      .collection('widget')
      .add({
        name: values.name,
        order: '',
        setting: {
          input: {
            calculation: widgetData.calculationInput,
            max_condition: values.input_max_condition,
            min_condition: values.input_min_condition,
            name: values.input_name,
            parameter_id: widgetData.idParameterInput,
          },
          output: {
            calculation: widgetData.calculationOutput,
            max_condition: values.output_max_condition,
            min_condition: values.output_min_condition,
            name: values.output_name,
            parameter_id: widgetData.idParameterOutput,
          },
          status_zone: {
            enable: widgetData.statusZone,
            normal: widgetData.normal,
            satisfied: widgetData.satisfied,
            unsatisfied: widgetData.unsatisfied,
          },
          multiply: widgetData.multiply,
          suffix: widgetData.suffix,
        },
      })
      .then(async(docRef) => {
        console.log('Document successfully deleted!')

        const newDocumentId = docRef.id

        await db.collection('project')
          .doc(`${id}`)
          .collection('widget')
          .doc(newDocumentId)
          .collection('log_day2')
          .add({
            start_timestamp: start_timestamp,
            stop_timestamp: next_timestamp,
            count_sec_input: count_sec_input,
            count_sec_output: count_sec_output,
            last_update: currentTimestamp,
          })
          .then((docRef) => {
            Swal.fire('Saved!', '', 'success').then((result) => {
              if (result.isConfirmed) {
                // window.location.href = '../dashboard'
                window.location.assign('../dashboard')
              }
            })
          })
          .catch((error) => {
            console.error('Error adding document: ', error)
            Swal.fire('Error!', '', 'error')
          })
      })
      .catch((error) => {
        console.error('Error removing document: ', error)
        Swal.fire('Error!', '', 'error')
      })
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2' style={{color: 'white'}}>
          Create Widget
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1' style={{color: 'white'}}>
            Active
          </span> */}
        </h3>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className='card card-custom card-stretch-100 shadow mb-5'>
              <Formik
                initialValues={initialDataForm}
                validationSchema={validationSchema}
                onSubmit={(values) => submitCreateUser(values)}
              >
                {(formDatas) => (
                  <form onSubmit={formDatas.handleSubmit}>
                    <div className='card-body'>
                      <div className='row d-flex justify-content-center align-items-center mb-10'>
                        <div className='col-6 col-lg-1 d-flex align-items-center'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            value='Name'
                            style={{color: 'inherit', textAlign: 'center', borderRadius: '10px'}}
                            disabled
                          />
                        </div>
                        <div className='col-6 col-lg-3'>
                          <input
                            type='text'
                            name='name'
                            className='form-control form-control-sm'
                            value={formDatas.values.name}
                            onChange={formDatas.handleChange}
                          />
                          {Boolean(formDatas.errors.name) && (
                            <p
                              className='errors'
                              style={{
                                color: 'red',
                                fontSize: '11px',
                              }}
                            >
                              {formDatas.errors.name}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className='row d-flex justify-content-around'>
                        {/* div left */}
                        <div className='col-12 col-lg-5'>
                          <div className='row mb-5 '>
                            <div className='form-group row'>
                              <label className='form-label col-4 mt-2'>Output Name</label>
                              <div className='col-sm-8'>
                                <input
                                  type='text'
                                  name='output_name'
                                  className='form-control form-control-sm'
                                  value={formDatas.values.output_name}
                                  onChange={formDatas.handleChange}
                                  // id='inputPassword2'
                                  // value={widgetData.outputName}
                                  // onChange={(e) =>
                                  //   setWidgetData({...widgetData, outputName: e.target.value})
                                  // }
                                />
                                {formDatas.touched.output_name &&
                                  Boolean(formDatas.errors.output_name) && (
                                    <p
                                      className='errors'
                                      style={{
                                        color: 'red',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {formDatas.errors.output_name}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-4 mt-2'>Parameter</label>

                              <div className='col-12 col-sm-8'>
                                <div className='input-group'>
                                  <input
                                    type='text'
                                    className='form-control form-control-sm'
                                    id='inputPassword'
                                    value={widgetData.parameterOutput}
                                    onChange={(e) =>
                                      setWidgetData({
                                        ...widgetData,
                                        parameterOutput: e.target.value,
                                      })
                                    }
                                  />
                                  <div className='input-group-append'>
                                    <button
                                      className='btn btn-primary btn-sm'
                                      type='button'
                                      onClick={addParameterOutput}
                                    >
                                      <i className='fa-solid fa-plus'></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <Modal
                                show={showModalOutput}
                                onHide={closeModalOutput}
                                animation={true}
                                size='lg'
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Output Parameter</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <AddParameter
                                    selectedRows={dataParameterOutput}
                                    setSelectedRows={setDataParameterOutput}
                                  />
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant='secondary' onClick={closeModalOutput}>
                                    Close
                                  </Button>
                                  <Button variant='primary' onClick={submitModalOutput}>
                                    Submit
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-4 mt-2'>Calculation</label>
                              <div className='col-sm-8'>
                                <select
                                  className='form-select form-select-sm'
                                  aria-label='Select example'
                                  value={widgetData.calculationOutput}
                                  onChange={(e) =>
                                    setWidgetData({
                                      ...widgetData,
                                      calculationOutput: e.target.value,
                                    })
                                  }
                                >
                                  {/* <option value='none'>None</option> */}
                                  {/* <option value='value'>Value</option>
                            <option value='differential'>Differential</option> */}
                                  <option value='event_counter'>Event Counter</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <div className='form-label col-4 mt-2'></div>

                              <label className='form-label col-4 mt-2'>Min condition</label>
                              <div className='col-sm-4'>
                                <input
                                  placeholder="'inf' or number"
                                  type='text'
                                  className='form-control form-control-sm col-sm-3'
                                  name='output_min_condition'
                                  value={formDatas.values.output_min_condition}
                                  // onChange={formDatas.handleChange}
                                  onChange={(e) => {
                                    formDatas.handleChange(e)
                                    setWidgetData({
                                      ...widgetData,
                                      minConditionOutput: e.target.value,
                                    })
                                  }}
                                  // id='inputPassword'
                                  // value={widgetData.minConditionOutput}
                                  // onChange={(e) =>
                                  //   setWidgetData({
                                  //     ...widgetData,
                                  //     minConditionOutput: e.target.value,
                                  //   })
                                  // }
                                />
                                {formDatas.touched.output_min_condition &&
                                  Boolean(formDatas.errors.output_min_condition) && (
                                    <p
                                      className='errors'
                                      style={{
                                        color: 'red',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {formDatas.errors.output_min_condition}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className='row mb-7'>
                            <div className='form-group row'>
                              <div className='form-label col-4 mt-2'></div>

                              <label className='form-label col-4 mt-2'>Max condition</label>
                              <div className='col-sm-4'>
                                <input
                                  placeholder="'inf' or number"
                                  type='text'
                                  className='form-control form-control-sm col-sm-3'
                                  name='output_max_condition'
                                  value={formDatas.values.output_max_condition}
                                  // onChange={formDatas.handleChange}
                                  onChange={(e) => {
                                    formDatas.handleChange(e)
                                    setWidgetData({
                                      ...widgetData,
                                      maxConditionOutput: e.target.value,
                                    })
                                  }}
                                  // id='inputPassword'
                                  // value={widgetData.maxConditionOutput}
                                  // onChange={(e) =>
                                  //   setWidgetData({
                                  //     ...widgetData,
                                  //     maxConditionOutput: e.target.value,
                                  //   })
                                  // }
                                />
                                {formDatas.touched.output_max_condition &&
                                  Boolean(formDatas.errors.output_max_condition) && (
                                    <p
                                      className='errors'
                                      style={{
                                        color: 'red',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {formDatas.errors.output_max_condition}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>

                          {widgetData.idParameterOutput !== '' ? (
                            <p
                              style={{cursor: 'pointer', color: '#009ef7'}}
                              onClick={handleShowChart}
                            >
                              {showChartOutput == true ? 'Hide' : 'Show'}
                            </p>
                          ) : (
                            ''
                          )}

                          {widgetData.idParameterOutput !== '' && showChartOutput == true ? (
                            <ChartParameterData
                              parameterId={widgetData.idParameterOutput}
                              minConditionOutput={widgetData.minConditionOutput}
                              maxConditionOutput={widgetData.maxConditionOutput}
                            ></ChartParameterData>
                          ) : (
                            ''
                          )}

                          <hr className='col-12' />

                          <div className='row mb-5 mt-7'>
                            <div className='form-group row'>
                              <label className='form-label col-4 mt-2'>Input Name</label>
                              <div className='col-sm-8'>
                                <input
                                  type='text'
                                  className='form-control form-control-sm'
                                  name='input_name'
                                  value={formDatas.values.input_name}
                                  onChange={formDatas.handleChange}
                                  // id='inputPassword'
                                  // value={widgetData.inputName}
                                  // onChange={(e) =>
                                  //   setWidgetData({...widgetData, inputName: e.target.value})
                                  // }
                                />
                                {formDatas.touched.input_name &&
                                  Boolean(formDatas.errors.input_name) && (
                                    <p
                                      className='errors'
                                      style={{
                                        color: 'red',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {formDatas.errors.input_name}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-4 mt-2'>Parameter</label>

                              <div className='col-12 col-sm-8'>
                                <div className='input-group'>
                                  <input
                                    type='text'
                                    className='form-control form-control-sm'
                                    id='inputPassword'
                                    value={widgetData.parameterInput}
                                    onChange={(e) =>
                                      setWidgetData({...widgetData, parameterInput: e.target.value})
                                    }
                                  />
                                  <div className='input-group-append'>
                                    <button
                                      className='btn btn-primary btn-sm'
                                      type='button'
                                      onClick={addParameterInput}
                                    >
                                      <i className='fa-solid fa-plus'></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <Modal
                                show={showModalInput}
                                onHide={closeModalInput}
                                animation={true}
                                size='lg'
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Input Parameter</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <AddParameter
                                    selectedRows={dataParameterInput}
                                    setSelectedRows={setDataParameterInput}
                                  />
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant='secondary' onClick={closeModalInput}>
                                    Close
                                  </Button>
                                  <Button variant='primary' onClick={submitModalInput}>
                                    Submit
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-4 mt-2'>Calculation</label>
                              <div className='col-sm-8'>
                                <select
                                  className='form-select form-select-sm'
                                  aria-label='Select example'
                                  value={widgetData.calculationInput}
                                  onChange={(e) =>
                                    setWidgetData({...widgetData, calculationInput: e.target.value})
                                  }
                                >
                                  {/* <option value='none'>None</option> */}
                                  {/* <option value='value'>Value</option>
                            <option value='differential'>Differential</option> */}
                                  <option value='event_counter'>Event Counter</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <div className='form-label col-4 mt-2'></div>

                              <label className='form-label col-4 mt-2'>Min condition</label>
                              <div className='col-sm-4'>
                                <input
                                  placeholder="'inf' or number"
                                  type='text'
                                  className='form-control form-control-sm col-sm-3'
                                  name='input_min_condition'
                                  value={formDatas.values.input_min_condition}
                                  // onChange={formDatas.handleChange}
                                  onChange={(e) => {
                                    formDatas.handleChange(e)
                                    setWidgetData({
                                      ...widgetData,
                                      minConditionInput: e.target.value,
                                    })
                                  }}
                                  // className='form-control form-control-sm col-sm-3'
                                  // id='inputPassword'
                                  // value={widgetData.minConditionInput}
                                  // onChange={(e) =>
                                  //   setWidgetData({
                                  //     ...widgetData,
                                  //     minConditionInput: e.target.value,
                                  //   })
                                  // }
                                />
                                {formDatas.touched.input_min_condition &&
                                  Boolean(formDatas.errors.input_min_condition) && (
                                    <p
                                      className='errors'
                                      style={{
                                        color: 'red',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {formDatas.errors.input_min_condition}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <div className='form-label col-4 mt-2'></div>

                              <label className='form-label col-4 mt-2'>Max condition</label>
                              <div className='col-sm-4'>
                                <input
                                  placeholder="'inf' or number"
                                  type='text'
                                  name='input_max_condition'
                                  value={formDatas.values.input_max_condition}
                                  // onChange={formDatas.handleChange}
                                  onChange={(e) => {
                                    formDatas.handleChange(e)
                                    setWidgetData({
                                      ...widgetData,
                                      maxConditionInput: e.target.value,
                                    })
                                  }}
                                  className='form-control form-control-sm col-sm-3'
                                  // id='inputPassword'
                                  // value={widgetData.maxConditionInput}
                                  // onChange={(e) =>
                                  //   setWidgetData({
                                  //     ...widgetData,
                                  //     maxConditionInput: e.target.value,
                                  //   })
                                  // }
                                />
                                {formDatas.touched.input_max_condition &&
                                  Boolean(formDatas.errors.input_max_condition) && (
                                    <p
                                      className='errors'
                                      style={{
                                        color: 'red',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {formDatas.errors.input_max_condition}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>

                          {widgetData.idParameterInput !== '' ? (
                            <p
                              style={{cursor: 'pointer', color: '#009ef7'}}
                              onClick={handleShowChartInput}
                            >
                              {showChartInput == true ? 'Hide' : 'Show'}
                            </p>
                          ) : (
                            ''
                          )}

                          {widgetData.idParameterInput !== '' && showChartInput == true ? (
                            <ChartParameterData
                              parameterId={widgetData.idParameterInput}
                              minConditionOutput={widgetData.minConditionInput}
                              maxConditionOutput={widgetData.maxConditionInput}
                            ></ChartParameterData>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='col-1 d-flex justify-content-center align-items-center'>
                          <VerticalSeparator />
                        </div>

                        {/* div right */}
                        <div className='col-12 col-lg-5'>
                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-3 mt-2'>Multiply</label>
                              <div className='col-sm-5'>
                                <input
                                  type='text'
                                  className='form-control form-control-sm'
                                  id='inputPassword'
                                  value={widgetData.multiply}
                                  onChange={(e) =>
                                    setWidgetData({...widgetData, multiply: e.target.value})
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-3 mt-2'>Suffix</label>
                              <div className='col-sm-5'>
                                <input
                                  type='text'
                                  className='form-control form-control-sm'
                                  id='inputPassword'
                                  value={widgetData.suffix}
                                  onChange={(e) =>
                                    setWidgetData({...widgetData, suffix: e.target.value})
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mb-16'></div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-3 mt-2'>Status Zone</label>
                              <div className='col-sm-5'>
                                <div className='form-check form-switch form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='flexSwitchDefault'
                                    checked={widgetData.statusZone} // Set the checked status from state
                                    onChange={handleCheckboxChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-3 mt-2'>Unsatisfied</label>
                              <div className='col-sm-5'>
                                <input
                                  type='text'
                                  className='form-control form-control-sm'
                                  id='inputPassword'
                                  disabled={!widgetData.statusZone}
                                  value={widgetData.statusZone ? widgetData.unsatisfied : ''}
                                  onChange={(e) => {
                                    const newValue = parseInt(e.target.value)
                                    if (!isNaN(newValue)) {
                                      // Ensure the value is an integer
                                      setWidgetData({
                                        ...widgetData,
                                        unsatisfied: newValue,
                                      })
                                    } else {
                                      // Handle invalid input (e.g., non-numeric values)
                                      setWidgetData({
                                        ...widgetData,
                                        unsatisfied: '', // Clear the input if it's not a valid number
                                      })
                                    }
                                  }}
                                />
                              </div>
                              <div className='col-sm-1 mt-3' style={{textAlign: 'center'}}>
                                <div style={dotStyleRed}></div>
                              </div>
                            </div>
                          </div>

                          <div className='row mb-5'>
                            <div className='form-group row'>
                              <label className='form-label col-3 mt-2'>Satisfied</label>
                              <div className='col-sm-5'>
                                <input
                                  type='text'
                                  className='form-control form-control-sm'
                                  id='inputPassword'
                                  disabled={!widgetData.statusZone}
                                  value={widgetData.statusZone ? widgetData.satisfied : ''}
                                  onChange={(e) => {
                                    const newValue = parseInt(e.target.value)

                                    if (!isNaN(newValue)) {
                                      // Ensure the value is an integer
                                      setWidgetData({
                                        ...widgetData,
                                        satisfied: newValue,
                                      })
                                    } else {
                                      // Handle invalid input (e.g., non-numeric values)
                                      setWidgetData({
                                        ...widgetData,
                                        satisfied: '', // Clear the input if it's not a valid number
                                      })
                                    }
                                  }}
                                />
                              </div>
                              <div className='col-sm-1 mt-3' style={{textAlign: 'center'}}>
                                <div style={dotStyleGreen}></div>
                              </div>
                            </div>
                          </div>

                          <div className='row mb-20'>
                            <div className='form-group row'>
                              <label className='form-label col-3 mt-2'>Normal (Auto)</label>
                              <div className='col-sm-5'>
                                <input
                                  type='text'
                                  className='form-control form-control-sm'
                                  id='inputPassword'
                                  disabled
                                  value={widgetData.normal !== '' ? `${widgetData.normal}` : ''}
                                  onChange={(e) =>
                                    setWidgetData({...widgetData, normal: e.target.value})
                                  }
                                />
                              </div>
                              <div className='col-sm-1 mt-3' style={{textAlign: 'center'}}>
                                <div style={dotStyleBlue}></div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className='d-flex justify-content-end'>
                            <div className='mt-20'>
                              <Link to={`/dashboard`}>
                                <button className='btn btn-secondary btn-sm'>Cancel</button>
                              </Link>
                              &nbsp;&nbsp;
                              <Button variant=' btn-primary btn-sm' type='submit'>
                                Submit
                              </Button>
                              {/* <a
                                href='#'
                                className='btn btn-primary btn-sm'
                                onClick={create_submit}
                              >
                                Submit
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

async function getSecondOfDataLog(list_log_input, min_condition, max_condition) {
  let count_sec_input = 0
  for (let index = 0; index < list_log_input.length; index++) {
    const item = list_log_input[index]
    if (item.v >= min_condition && list_log_input[index + 1] !== undefined) {
      const item_next = list_log_input[index + 1]
      count_sec_input += item_next.t - item.t
    }
  }
  return count_sec_input
}

async function getSecondOfDataLogCondition2(list_log_input, min_condition, max_condition) {
  let count_sec_input = 0
  for (let index = 0; index < list_log_input.length; index++) {
    const item = list_log_input[index]
    if (
      item.v <= max_condition &&
      item.v >= min_condition &&
      list_log_input[index + 1] !== undefined
    ) {
      const item_next = list_log_input[index + 1]
      count_sec_input += item_next.t - item.t
    }
  }
  return count_sec_input
}

async function getSecondOfDataLogCondition3(list_log_input, min_condition, max_condition) {
  let count_sec_input = 0
  for (let index = 0; index < list_log_input.length; index++) {
    const item = list_log_input[index]
    if (item.v <= max_condition && list_log_input[index + 1] !== undefined) {
      const item_next = list_log_input[index + 1]
      count_sec_input += item_next.t - item.t
    }
  }
  return count_sec_input
}
